import styled from 'styled-components';
import { Presets } from 'rete-react-plugin';
import { useTranslation } from 'react-i18next';
import {
    AccessTime,
    AppRegistration,
    Bookmark,
    Http,
    Input,
    ListAlt,
    LocationOn,
    Mms,
    MoreTime,
    PersonAdd,
    PersonRemove,
    Schema,
    Textsms,
    PlayArrow,
    Delete,
    Email,
    SmartToy,
    SmartButton,
    RadioButtonChecked,
    Outbound,
    MoveUp,
    BackupTable,
    Message,
    Keyboard,
    FactCheck,
    NextPlan,
    AdminPanelSettings,
    Tune,
    Bookmarks,
    Feed,
} from '@mui/icons-material';

const { Menu, Common, Search, Item, Subitems } = Presets.contextMenu;

export const CustomMenu = styled(Menu)`
    color: rgb(255, 255, 255);
    width: auto;
`;

export const CustomItem = styled(Item)`
    color: rgb(255, 255, 255);
    padding: 4px;
    border-bottom: 1px solid rgb(36 56 133) !important;
    background-color: rgb(36 56 133 / 63%) !important;
    cursor: pointer;
    width: 100%;
    position: relative;
    :hover {
        background-color: rgb(36 56 133 / 100%) !important;
    }
`;

export const CustomSubitems = styled(Subitems)`
    background: #0000003b;
    width: 100%;
`;

export const CustomSearch = styled(Search)`
    border-bottom: 1px solid rgb(36 56 133) !important;
    background: #0000003b;
    display: none;
`;

export const CustomCommon = styled(Common)`
    border-bottom: 1px solid rgb(36 56 133) !important;
    background-color: rgb(36 56 133 / 50%) !important;
    :hover {
        background-color: rgb(36 56 133 / 80%) !important;
    }
`;

/** Custom Items context menu */
export const CustomItemWithIcon = (props:any) => {
    const { t } = useTranslation();
    if(props.data.subitems) {
        return(
            <CustomItem {...props.data} className='dropdown'>
                <div className='constextMenuCustomItem'>
                    <span className='marginRightIcons'>{selectedIconNode(props.data.key)}</span>
                    <span>{props.data.label === 'Delete' ? t('nodes.delete') : props.data.label}</span>
                    <span className='dropdownIcon'>➤</span>
                </div>
                <CustomSubitems className='dropdownContent'>
                    {props.data.subitems.map((item:any) => {
                        return (
                            <CustomItem key={item.key} {...item} onClick={() => handlerContextMenu(item)}>
                                <div className='constextMenuCustomItem'>
                                    <span className='marginRightIcons'>{selectedIconSubNode(props.data.key, item.key)}</span>
                                    <span>{item.label === 'Delete' ? t('nodes.delete') : item.label}</span>
                                </div>
                            </CustomItem>
                        );
                    })}
                </CustomSubitems>
            </CustomItem>
        );
    }
    else{
        return (
            <CustomItem {...props.data} onClick={() => handlerContextMenu(props.data)}>
                <div className='constextMenuCustomItem'>
                    <span className='marginRightIcons'>{selectedIconNode(props.data.key)}</span>
                    <span>{props.data.label === 'Delete' ? t('nodes.delete') : props.data.label}</span>
                </div>
            </CustomItem>
        );
    }
};

/** call handler and after close context menu make hide*/
const handlerContextMenu = (data:any) => {
    data.handler();
    const elements = document.querySelector('div[data-testid="context-menu"]');
    elements?.setAttribute('style', 'display: none');
};

/** Return icon by node first level */
const selectedIconNode = (keyNode: string) => {
    switch (keyNode) {
    case '0':// Start
        return <PlayArrow fontSize='small' />;
    case '1':// Distribute Chat
        return <Schema fontSize='small' />;
    case '2':// Messages Submenu
        return <Message fontSize='small' />;
    case '3':// Inputs Submenu
        return <Keyboard fontSize='small' />;
    case '4':// Validations Submenu
        return <FactCheck fontSize='small' />;
    case '5':// Subroutines Submenu
        return <NextPlan fontSize='small' />;
    case '6':// Advanced Submenu
        return <AdminPanelSettings fontSize='small' />;
    case '7':// Utils Submenu
        return <Tune fontSize='small' />;
    case 'delete':// Delete
        return <Delete fontSize='small' />;
    default:
        return <Bookmarks fontSize='small' />;
    }
};

/** Return icon by node second level */
const selectedIconSubNode = (keyNode:string, KeySubNode: string) => {
    let icon: React.ReactElement = <Bookmark fontSize='small' />;
    switch (keyNode) {
    case '2':// Messages Submenu
        // Message
        if(KeySubNode === '0') icon = <Textsms fontSize='small' />;
        // Media
        else if(KeySubNode === '1') icon =  <Mms fontSize='small' />;
        // Location
        else if(KeySubNode === '2') icon =  <LocationOn fontSize='small' />;
        return icon;
    case '3':// Inputs Submenu
        // Input
        if(KeySubNode === '0') icon =  <Input fontSize='small' />;
        // Interactive
        else if(KeySubNode === '1') icon = <SmartButton fontSize='small' />;
        else if(KeySubNode === '2') icon = <Feed fontSize='small' />;
        return icon;
    case '4':// Validations Submenu
        // Check Time
        if(KeySubNode === '0') icon = <AccessTime fontSize='small' />;
        // Switch
        else if(KeySubNode === '1') icon =  <ListAlt fontSize='small' />;
        return icon;
    case '5':// Subroutines Submenu
        // Add to StartSub
        if(KeySubNode === '0') icon =  <RadioButtonChecked fontSize='small' />;
        // Subroutine
        else if(KeySubNode === '1') icon = <Outbound fontSize='small' />;
        // StartSection
        else if(KeySubNode === '2') icon =  <BackupTable fontSize='small' />;
        // Goto
        else if(KeySubNode === '3') icon =  <MoveUp fontSize='small' />;
        return icon;
    case '6':// Advanced Submenu
        // HttpRequest
        if(KeySubNode === '0') icon = <Http fontSize='small' />;
        // GptAssistant
        else if(KeySubNode === '1') icon =  <SmartToy fontSize='small' />;
        return icon;
    case '7':// Utils Submenu
        // Invite User
        if(KeySubNode === '0') icon = <PersonAdd fontSize='small' />;
        // Leave
        else if(KeySubNode === '1') icon =  <PersonRemove fontSize='small' />;
        // Email
        else if(KeySubNode === '2') icon =  <Email fontSize='small' />;
        // Set Vars
        else if(KeySubNode === '3') icon =  <AppRegistration fontSize='small' />;
        //Delay
        else if(KeySubNode === '4') icon =  <MoreTime fontSize='small' />;
        return icon;
    default:
        return <Bookmarks fontSize='small' />;
    }
};