import { Case } from '../../../models/Switch';
import {
    TextField,
    Tooltip,
    IconButton,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Box,
} from '@mui/material';
import { Remove, PostAdd, ExpandMore, Dehaze, ExpandLess } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { useState } from 'react';

declare type CaseComponentProps = {
    data: Case;
    index: number;
    expanded: boolean;
    textFileWidth: string;
    removeCase: (index: number) => void;
    saveCase: (index: number, key: 'id' | 'o_connection', value: string) => void;
    addVariable: (caseIndex: number) => void;
    removeVariable: (caseIndex: number, index: number) => void;
    saveVariable: (index: number, caseIndex: number, key: 'key' | 'value', value: string) => void;
};

export const CaseComponent = (props: CaseComponentProps): JSX.Element => {
    const { t } = useTranslation();
    const [expandedVariables, setExpandedVariables] = useState<boolean>(false);

    return (
        <Draggable
            draggableId={`case-option-${props.index}`}
            index={props.index}
            key={`case-option-${props.index}`}
        >
            {(provided: DraggableProvided) => (
                <Box
                    className='rt_content_cases'
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    <Accordion
                        expanded={props.expanded}
                        sx={{
                            background: 'none',
                            border: 'none',
                            boxShadow: 'none',
                        }}
                    >
                        <AccordionSummary
                            aria-controls="panel3-content"
                        >
                            <Grid
                                container
                                spacing={1}
                                sx={{
                                    height: '7vh',
                                    alignContent: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        bottom: props.expanded ? 90 : 75,
                                        position: 'absolute',
                                        margin: '0 !important',
                                        padding: '0 !important',
                                        zIndex: 100,
                                        maxWidth: 'min-content',
                                    }}
                                    {...provided.dragHandleProps}
                                >
                                    <Tooltip title={t('case_component.move_case')} >
                                        <IconButton
                                            aria-label='move-interactive-option'
                                            size='small'
                                            sx={{
                                                ml: 1,
                                                width: '47px',
                                                background: 'white',
                                                borderRadius: '6px',
                                                border: '1px dashed',
                                                zIndex: 50,
                                                margin: '0 !important',
                                                marginLeft: '0 !important',
                                            }}
                                            {...provided.dragHandleProps}
                                        >

                                            <Dehaze sx={{ fontSize: '1rem' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid container spacing={1} sx={{ mt: 1 }}>
                                    <Grid item xs={11}>
                                        <TextField
                                            label="ID"
                                            id={`case-id-${props.index}`}
                                            value={props.data.id}
                                            onChange={(e): void => props.saveCase(props.index, 'id', e.target.value)}
                                            required
                                            sx={{ ml: 1, mr: 1, width: props.textFileWidth }}
                                            size='small'
                                        />
                                        <TextField
                                            label={t('case_component.output_connection')}
                                            id={`case-o-connection-${props.index}`}
                                            value={props.data.o_connection}
                                            onChange={(e): void => props.saveCase(props.index, 'o_connection', e.target.value)}
                                            sx={{ ml: 1, width: props.textFileWidth }}
                                            size='small'
                                        />
                                    </Grid>
                                    <Grid item xs={1} alignSelf={'center'}>
                                        <Box className='remove-case' textAlign={'center'}>
                                            <Tooltip title={t('case_component.remove_case')}>
                                                <IconButton
                                                    size='small'
                                                    aria-label="remove-case"
                                                    onClick={(): void => props.removeCase(props.index)}
                                                >
                                                    <Remove sx={{ fontSize: '1rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={0} className="rm_margin_content">
                                <Grid item xs={11} sx={{ pr: 2 }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', mb: 1 }}>
                                        <Box className='expand-case-option' sx={{ mr: 1 }}>
                                            <Tooltip title={t('case_component.add_variable')} >
                                                <IconButton
                                                    size='small'
                                                    aria-label="add-variable"
                                                    onClick={(): void => props.addVariable(props.index)}
                                                >
                                                    <PostAdd sx={{ fontSize: '1.2rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                        <Box className='expand-case-option' display={'inline'}>
                                            <IconButton
                                                aria-label='expand-interactive-item'
                                                onClick={() => { setExpandedVariables(!expandedVariables); }}
                                            >
                                                {!expandedVariables && <ExpandMore sx={{ fontSize: '1rem' }} />}
                                                {expandedVariables && <ExpandLess sx={{ fontSize: '1rem' }} />}
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={11} sx={{ pr: 2 }}>
                                    <Accordion
                                        className='rt_cases_accordion'
                                        expanded={expandedVariables}
                                    >
                                        <AccordionSummary
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            {t('case_component.variables')}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {(props.data.variables ?? []).map((variable, index) => (
                                                <div key={`variable-${props.index}-${index}`}>
                                                    <TextField
                                                        label={t('case_component.key')}
                                                        id={`variable-key-${props.index}`}
                                                        value={variable.key}
                                                        onChange={(e): void => props.saveVariable(index, props.index, 'key', e.target.value)}
                                                        sx={{ mb: 1, width: '43%' }}
                                                        size='small'
                                                    />
                                                    <TextField
                                                        label={t('case_component.value')}
                                                        id={`variable-value-${props.index}`}
                                                        value={variable.value}
                                                        onChange={(e): void => props.saveVariable(index, props.index, 'value', e.target.value)}
                                                        sx={{ ml: 1.5, width: '43%' }}
                                                        size='small'
                                                    />
                                                    <Box className='remove-case' sx={{ display: 'inline' }}>
                                                        <Tooltip title={t('case_component.remove_variable')} >
                                                            <IconButton
                                                                aria-label="remove-variable"
                                                                size='small'
                                                                sx={{ ml: 1.5, mt: 1 }}
                                                                onClick={(): void => props.removeVariable(props.index, index)}
                                                            >
                                                                <Remove sx={{ fontSize: '1rem' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                </div>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            )}
        </Draggable>
    );
};