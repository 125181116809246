import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    Droppable,
    DroppableProvided,
    DropResult,
} from 'react-beautiful-dnd';
import {
    Typography,
    IconButton,
    TextField,
    Grid,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tooltip,
} from '@mui/material';
import { Add, Dehaze, ExpandLess, ExpandMore, Remove } from '@mui/icons-material';
import { InteractiveAction, InteractiveItems, InteractiveOption } from '../../models/Interactive';
import { t } from 'i18next';
import { reorder } from '../utils/reorderOnDragList';
import InteractiveListOptions from './InteractiveListOptions';
import { useEffect, useState } from 'react';

const InteractiveLists = (
    props: {
        index_item: number,
        item: InteractiveOption | InteractiveItems | InteractiveAction,
        interactiveOptionsValues: InteractiveOption[] | InteractiveItems[] | InteractiveAction[],
        setInteractiveOptionsValues: (interactiveOptionsValues: InteractiveOption[] | InteractiveItems[] | InteractiveAction[]) => void,
        expanded: boolean,
        setExpanded: (expanded: boolean) => void,
        handleExpandClick: () => void,
    }
) => {

    const [expandedOptions, setExpandedOptions] = useState<boolean>(true);
    const [interactiveOptions, setInteractiveOptions] = useState<InteractiveOption[]>([]);

    useEffect(() => {
        if (props.interactiveOptionsValues[props.index_item] && (props.interactiveOptionsValues[props.index_item] as InteractiveItems).options !== undefined) {
            setInteractiveOptions((props.interactiveOptionsValues[props.index_item] as InteractiveItems).options);
        }
    }, [props.interactiveOptionsValues]);

    const handleExpandOptionClick = () => {
        setExpandedOptions((prev) => !prev);
    };

    const onDragEndOptions = (result: DropResult, index: number): void => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const interactiveSectionOptions:
            InteractiveOption[] |
            InteractiveItems[] |
            InteractiveAction[] = reorder(
                (props.interactiveOptionsValues[index] as InteractiveItems).options,
                result.source.index,
                result.destination.index
            );

        props.setInteractiveOptionsValues([
            ...props.interactiveOptionsValues.slice(0, index),
            {
                ...props.interactiveOptionsValues[index],
                options: interactiveSectionOptions as InteractiveOption[],
            },
            ...props.interactiveOptionsValues.slice(index + 1),
        ] as InteractiveItems[]);
    };

    const removeInteractiveOption = (index: number): void => {
        if (props.interactiveOptionsValues.length > 1) {
            const newInteractiveOptionsValues = [...props.interactiveOptionsValues];
            newInteractiveOptionsValues.splice(index, 1);
            props.setInteractiveOptionsValues(newInteractiveOptionsValues as InteractiveOption[]);
        }
    };

    const addInteractiveListOption = (index_item: number): void => {
        const newInteractiveOptionsValues = [...props.interactiveOptionsValues];
        if ((newInteractiveOptionsValues[index_item] as InteractiveItems).options === undefined) {
            (newInteractiveOptionsValues[index_item] as InteractiveItems).options = [];
        }
        if ((newInteractiveOptionsValues[index_item] as InteractiveItems).options.length < 7) {
            (newInteractiveOptionsValues[index_item] as InteractiveItems).options.push({
                type: 'text',
                title: '',
                description: '',
                postback_text: '',
            });
            props.setInteractiveOptionsValues(newInteractiveOptionsValues as InteractiveItems[]);
        }
    };

    return (
        <Draggable
            draggableId={`button-${props.index_item}`}
            index={props.index_item}
            key={`interactive-item-list-${props.index_item}`}
        >
            {(provided: DraggableProvided) => (
                <Grid
                    container
                    className='section-list-container'
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    <Accordion
                        defaultExpanded
                        expanded={props.expanded}
                        sx={{
                            background: 'none',
                            border: 'none',
                            boxShadow: 'none',
                        }}
                    >
                        <AccordionSummary
                            aria-controls="panel3-content"

                        >
                            <Grid container spacing={0}>
                                <Grid container spacing={0}>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            alignSelf: 'center',
                                            textAlign: 'center',
                                            height: '0',
                                        }}
                                    >
                                        <Tooltip title={t('interactive.tooltip_move_section')}>
                                            <IconButton
                                                aria-label='move-interactive-option'
                                                size='small'
                                                sx={{
                                                    ml: 1,
                                                    bottom: 45,
                                                    width: '47px',
                                                    background: 'white',
                                                    borderRadius: '6px',
                                                    border: '1px dashed',
                                                }}
                                                {...provided.dragHandleProps}
                                            >
                                                <Dehaze sx={{ fontSize: '1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12} sx={{ ml: 1 }}>
                                        <Typography textAlign='left' variant='subtitle1' sx={{ fontWeight: 900 }}>
                                            {`${t('interactive.item')} ${props.index_item + 1}`}
                                        </Typography>
                                    </Grid>
                                    {/*Title of the section*/}
                                    <Grid item xs={5}>
                                        <TextField
                                            label={t('interactive.title')}
                                            id={`interactive-title-list-${props.index_item}`}
                                            value={(props.item as InteractiveItems).title ?? ''}
                                            onChange={(e): void => {
                                                const newInteractiveOptionsValues = [...props.interactiveOptionsValues];
                                                (newInteractiveOptionsValues[props.index_item] as InteractiveItems).title = e.target.value;
                                                props.setInteractiveOptionsValues(newInteractiveOptionsValues as InteractiveItems[]);
                                            }}
                                            required
                                            sx={{ mt: 1, ml: 1, width: '100%' }}
                                            size="small"
                                            inputProps={{ maxLength: 24 }}
                                        />
                                    </Grid>
                                    {/*subtitle of the section*/}
                                    <Grid item xs={5}>
                                        <TextField
                                            label={t('interactive.subtitle')}
                                            id={`interactive-subtitle-${props.index_item}`}
                                            value={(props.item as InteractiveItems).subtitle ?? ''}
                                            onChange={(e): void => {
                                                const newInteractiveOptionsValues = [...props.interactiveOptionsValues];
                                                (newInteractiveOptionsValues[props.index_item] as InteractiveItems).subtitle = e.target.value;
                                                props.setInteractiveOptionsValues(newInteractiveOptionsValues as InteractiveItems[]);
                                            }}
                                            sx={{ mt: 1, ml: 3.2, width: '100%' }}
                                            size="small"
                                            inputProps={{ maxLength: 24 }}
                                        />
                                    </Grid>
                                    {/*Button that delete an option of the section*/}
                                    <Grid item xs={2}>
                                        <Box className='remove-interactive-option' sx={{ ml: 4, mt: 1 }}>
                                            <Tooltip title={t('interactive.tooltip_remove_section')}>
                                                <IconButton
                                                    aria-label='remove-interactive-option'
                                                    sx={{ mt: 1, ml: 1.3 }}
                                                    onClick={(): void => { removeInteractiveOption(props.index_item); }}
                                                >
                                                    <Remove sx={{ fontSize: '1rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid
                                container
                                spacing={0}
                                sx={{
                                    alignItems: 'center',
                                }}
                            >
                                <Grid item xs={9} sx={{ ml: 1, mr: 4 }}>
                                    <Typography
                                        textAlign='left'
                                        variant='h6'
                                        sx={{ fontSize: '1.1rem' }}
                                    >
                                        {t('interactive.options')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ textAlign: 'center' }}>
                                    {/*Button that add an option of the section*/}
                                    <Box className='add-interactive-option add-interactive-item' sx={{ ml: 3 }}>
                                        <Tooltip title={t('interactive.tooltip_add_option')}>
                                            <IconButton
                                                aria-label='add-interactive-item'
                                                onClick={() => addInteractiveListOption(props.index_item)}>
                                                <Add sx={{ fontSize: '1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                <Grid item xs={1}>
                                    {/*Button to expand the section options*/}
                                    <Box className='expand-interactive-option' sx={{ ml: 2 }}>
                                        <IconButton
                                            aria-label='expand-interactive-item'
                                            onClick={() => { handleExpandOptionClick(); }}
                                        >
                                            {!expandedOptions && <ExpandMore sx={{ fontSize: '1rem' }} />}
                                            {expandedOptions && <ExpandLess sx={{ fontSize: '1rem' }} />}
                                        </IconButton>
                                    </Box>
                                </Grid>
                                <Grid container spacing={2} className='interactiveItems' sx={{ mt: .2 }}>
                                    {/*
                                        Drag and Drop component that allow the user to move the position of the options
                                    */}
                                    <DragDropContext onDragEnd={(result: DropResult) => onDragEndOptions(result, props.index_item)}>
                                        <Droppable droppableId="droppableSectionOptions">
                                            {(providedOptions: DroppableProvided) => (
                                                <Grid
                                                    container
                                                    spacing={1}
                                                    sx={{ mt: .5, pl: 3.2 }}
                                                    ref={providedOptions.innerRef}
                                                    {...providedOptions.droppableProps}
                                                >
                                                    {
                                                        // Map the options of the section to display them
                                                        interactiveOptions.map(
                                                            (option: InteractiveOption | InteractiveItems | InteractiveAction, index: number) =>
                                                                <InteractiveListOptions
                                                                    key={`interactive-list-component-${index}-${props.index_item}`}
                                                                    index_item={props.index_item}
                                                                    index={index}
                                                                    item={option}
                                                                    interactiveOptionsValues={props.interactiveOptionsValues}
                                                                    setInteractiveOptionsValues={props.setInteractiveOptionsValues}
                                                                    expandedOptions={expandedOptions}
                                                                    setExpandedOptions={setExpandedOptions}
                                                                    handleExpandOptionClick={handleExpandOptionClick}
                                                                />
                                                        )
                                                    }
                                                    {providedOptions.placeholder}
                                                </Grid>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            )}
        </Draggable>
    );
};

export default InteractiveLists;