import { useState } from 'react';
import { Box, IconButton, List, ListItem, Popover, Tooltip, Typography } from '@mui/material';
import { Keyboard } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const KeyboardShortcuts = () => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'shortcuts-popover' : undefined;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="subtitle2">
                {t('Keyboard Shortcuts')}
            </Typography>
            <Tooltip title={t('Show keyboard shortcuts')}>
                <IconButton
                    size="small"
                    onClick={handleClick}
                    sx={{ ml: 1 }}
                    color="primary"
                >
                    <Keyboard fontSize="small" />
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ p: 2 }}>
                    <List dense>
                        {/* TODO: improve the performance of the node sorting function
                        <ListItem>
                            <Typography variant="caption">
                                <kbd>Ctrl</kbd> + <kbd>O</kbd> / <kbd>A</kbd> - {t('Arrange selected nodes')}
                            </Typography>
                        </ListItem> */}
                        <ListItem>
                            <Typography variant="caption">
                                <kbd>Ctrl</kbd> - {t('Press and hold to select multiple nodes or to move them together')}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="caption">
                                <kbd>Ctrl</kbd> + <kbd>C</kbd> - {t('Copy selected nodes')}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="caption">
                                <kbd>Ctrl</kbd> + <kbd>V</kbd> - {t('Paste nodes')}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="caption">
                                <kbd>Ctrl</kbd> + <kbd>X</kbd> - {t('Cut selected nodes')}
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="caption">
                                <kbd>Shift</kbd> - {t('Press and hold to select multiple nodes with the left mouse button')}
                            </Typography>
                        </ListItem>
                    </List>
                </Box>
            </Popover>
        </Box>
    );
};