import { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Cancel, CheckCircle } from '@mui/icons-material';

interface EditModuleModalProps {
    open: boolean;
    currentName: string;
    onClose: () => void;
    onSave: (oldName: string, newName: string) => void;
}

export const EditModuleModal = ({ open, currentName, onClose, onSave }: EditModuleModalProps): JSX.Element => {
    const [newName, setNewName] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setNewName(currentName);
    }, [currentName]);

    const handleSave = () => {
        if (newName && newName !== currentName) {
            onSave(currentName, newName);
        }
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth={true}>
            <DialogTitle>{t('edit_module')}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label={t('module_name')}
                    type="text"
                    fullWidth
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('cancel')}</Button>
                <Button type="submit" variant="contained" onClick={handleSave} startIcon={<CheckCircle />}>{t('accept')}</Button>
            </DialogActions>
        </Dialog>
    );
};