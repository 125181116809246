import config from '../config.json';

type Queue = {
    room_id: string
    name: string
    description: string
};

type User = {
    id: number
    mxid: string
    management_room: string
    role: string
    agent_max_chats: number
    assigned_chats: number
    displayname: string
};

export class AcdService {
    base_url: string;
    bot_mxid: string;

    constructor(service: string, bot_mxid: string) {
        this.base_url = config.acd_as.public_url.replace('<domain>', service);
        this.bot_mxid = bot_mxid;
    }

    getQueues = async (): Promise<[{ queues: Queue[] }, number]> => {
        const url = `${this.base_url}/v1/cmd/queue/list`;
        try {
            const response = await fetch(`${url}`, {
                method: 'get',
                headers: {
                    'Authorization': 'Mxid ' + this.bot_mxid,
                },
            });
            const json: { queues: Queue[] } = await response.json();
            const status: number = response.status;
            return [json, status];
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    getUsers = async (role: string): Promise<[{ users: User[] }, number]> => {
        const url = `${this.base_url}/v1/user/${role}`;
        try {
            const response = await fetch(`${url}`, {
                method: 'get',
                headers: {
                    'Authorization': 'Mxid ' + this.bot_mxid,
                },
            });
            const json: { users: User[] } = await response.json();
            const status: number = response.status;
            return [json, status];
        } catch (error) {
            console.error(error);
            throw error;
        }
    };
}