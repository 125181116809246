import React, { useEffect, useMemo, useState } from 'react';
import { ClassicPreset as Classic } from 'rete';
import '../Modal.css';
import {
    Button,
    Typography,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Box,
} from '@mui/material';
import { Settings, Cancel, CheckCircle } from '@mui/icons-material';
import { DiContainer, NodeID } from '../../../types';
import { InteractiveData, InteractiveOption, InteractiveItems, InteractiveAction } from '../../../models/Interactive';
import { checkJinja2Syntaxis, getNextNodeName, nodeNameAlreadyExists } from '../../../../util';
import { t } from 'i18next';
import InteractiveForm from '../../../components/interactive/InteractiveForm';
import PreviewMessage from '../../../components/interactive/PreviewMessage';

declare type InteractiveModalControlOptions = {
    modalInfo?: InteractiveData;
    nodeId: NodeID;
    di: DiContainer;
    socket: Classic.Socket;
};

export class InteractiveModalControl extends Classic.Control {
    constructor(public options?: InteractiveModalControlOptions) {
        super();
    }
}

export const InteractiveModal = (props: { data: InteractiveModalControl }): JSX.Element => {
    const interactive_message_type: string | undefined = props.data.options?.modalInfo?.interactive_message?.type;
    const interactive_button_option: string = interactive_message_type === 'quick_reply' ? 'button' : interactive_message_type ? interactive_message_type : 'button';
    const [formErrorVisible, setFormErrorVisible] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [interactiveTypeOption, setInteractiveTypeOption] = useState<string>(interactive_button_option);
    const [interactiveTypeMedia, setInteractiveTypeMedia] = useState<string>(props.data.options?.modalInfo?.interactive_message.content?.type ?? 'text');
    const [enableInactivityOptions, setEnableInactivityOptions] = useState(false);
    const [interactiveData, setInteractiveData] = useState<InteractiveData>({
        name: getNextNodeName('Interactive', 'interactive_input', props.data.options?.di.editor!),
        variable: '',
        validation: '',
        validation_fail: {
            message: '',
            attempts: '',
        },
        inactivity_options: {},
        interactive_message: {
            type: 'quick_reply',
            content: {
                type: '',
                text: '',
                caption: '',
            },
            options: [{
                type: '',
                title: '',
            }],
        },
        cases: [
            { id: 'default', o_connection: '', variables: [] },
        ],
    });
    const [interactiveOptionsValues, setInteractiveOptionsValues] = useState<InteractiveOption[] | InteractiveItems[] | InteractiveAction[]>(
        props.data.options?.modalInfo?.interactive_message.action ||
        props.data.options?.modalInfo?.interactive_message.items ||
        props.data.options?.modalInfo?.interactive_message.options ||
        [{
            type: 'text',
            title: '',
        }]
    );

    const formatInText = (text: string): string => {
        // Change the format of the text with the editor format like:
        // *_text_* -> *__text__*
        // _*text*_ -> *__text__*
        // _text_ -> *text*
        // *text* -> __text__

        return text
            .replaceAll('_', '~')
            .replaceAll('*', '__')
            .replaceAll('~', '*');
    };

    const formatOutText = (text: string): string => {
        // Change the format of the text with the whatsapp format like:
        // *__text__* -> *_text_*
        // __*text*__ -> *_text_*
        // *text* -> _text_
        // __text__ -> *text*

        return text
            .replaceAll('__', '~')
            .replaceAll('*', '_')
            .replaceAll('~', '*');
    };

    useEffect(() => {
        const inactivityOptionsState: boolean = (
            interactiveData.inactivity_options?.chat_timeout !== '' &&
            interactiveData.inactivity_options?.chat_timeout !== null &&
            interactiveData.inactivity_options?.warning_message !== undefined
        );
        setEnableInactivityOptions(inactivityOptionsState);
    }, []);

    useMemo(() => {
        if (props.data.options?.modalInfo && !open) {
            let node_data_copy = JSON.parse(JSON.stringify(props.data.options.modalInfo));
            node_data_copy = {
                ...node_data_copy,
                interactive_message: {
                    ...node_data_copy.interactive_message,
                    content: {
                        ...node_data_copy.interactive_message.content,
                        text: formatInText(node_data_copy.interactive_message.content?.text || ''),
                    },
                    body: formatInText(node_data_copy.interactive_message.body as string || ''),
                },
            };
            setInteractiveData(node_data_copy);
        }
    }, [open]);

    const onOpen = () => () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const checkAddConnection = (value: string): boolean => {
        // Check if the value of the connection is a jinja2 variable or the string 'finish'
        // It is used to determine if the connection should be added to the node
        return checkJinja2Syntaxis(value) || value === 'finish';
    };

    const updateOutputConnection = (): void => {
        const di = props.data.options!.di;
        const node = di.editor.getNode(props.data.options!.nodeId.reteNodeId);
        const socket = props.data.options!.socket;
        const outputIDs: string[] = [];
        const casesID: string[] = [];

        if (node.outputs) {
            for (const output in node.outputs) {
                outputIDs.push(output);
            }
        }

        interactiveData.cases.forEach((item) => {
            if (!checkAddConnection(item.o_connection)) {
                casesID.push(item.id);
            }
        });

        outputIDs.map((output) => {
            if (!casesID.includes(output)) {
                node.removeOutput(output);
            }
        });

        interactiveData.cases.forEach((item) => {
            if (!outputIDs.includes(item.id) && !checkAddConnection(item.o_connection)) {
                node.addOutput(item.id, new Classic.Output(socket, item.id, false));
            }
        });
        di.updateNode(props.data.options!.nodeId.reteNodeId);
    };

    const updateNodeHeigth = (): void => {
        const nodeHeigth = 180 + (interactiveData.cases.length * 30);
        const editor_node = props.data.options!.di.editor.getNode(props.data.options!.nodeId.reteNodeId);
        editor_node.height = nodeHeigth;
        props.data.options!.di.updateNode(props.data.options!.nodeId.reteNodeId);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (nodeNameAlreadyExists(interactiveData.name, props.data.options?.nodeId!, props.data.options?.di.editor!)) {
            showErrorMessage(t('interactive.name_already_exists'));
            return;
        }

        if (interactiveTypeOption === 'button') {
            if (interactiveTypeMedia != 'text' && !interactiveData.interactive_message.content?.url) {
                showErrorMessage(t('interactive.you_must_fill_the_url_field'));
                return;
            }

            props.data.options!.modalInfo = {
                name: interactiveData.name,
                variable: interactiveData.variable,
                validation: interactiveData.validation,
                validation_fail: interactiveData.validation_fail,
                inactivity_options: interactiveData.inactivity_options,
                interactive_message: {
                    type: interactiveData.interactive_message.type,
                    content: {
                        type: interactiveTypeMedia,
                        url: interactiveData.interactive_message.content?.url,
                        text: formatOutText(
                            interactiveData.interactive_message.content?.text ?? ''
                        ),
                        header: interactiveData.interactive_message.content?.header || '',
                        caption: interactiveData.interactive_message.content?.caption || '',
                    },
                    options: interactiveOptionsValues as InteractiveOption[],
                },
                cases: interactiveData.cases,
            };

        } else {
            props.data.options!.modalInfo = {
                name: interactiveData.name,
                variable: interactiveData.variable,
                validation: interactiveData.validation,
                validation_fail: interactiveData.validation_fail,
                inactivity_options: interactiveData.inactivity_options,
                interactive_message: {
                    type: interactiveData.interactive_message.type,
                    title: interactiveData.interactive_message.title,
                    body: formatOutText(interactiveData.interactive_message.body as string),
                    msgid: interactiveData.interactive_message.msgid,
                    global_buttons: interactiveData.interactive_message.global_buttons,
                    items: interactiveOptionsValues as InteractiveItems[],
                },
                cases: interactiveData.cases,
            };
        }

        if (enableInactivityOptions) {
            const options = interactiveData.inactivity_options;
            if (!options?.chat_timeout || !options?.warning_message || !options?.time_between_attempts || !options?.attempts) {
                showErrorMessage(t('interactive.you_must_fill_all_inactivity_options_fields'));
                return;
            }
        }

        props.data.options!.di.updateControl(props.data.id);
        props.data.options?.di.updateControl(props.data.id);
        updateOutputConnection();
        updateNodeHeigth();
        onClose();
    };

    return (
        <>
            <IconButton
                aria-label="Node Options"
                size="small"
                className='open-button'
                onPointerDown={(e): void => e.stopPropagation()}
                onClick={onOpen()}
            >
                <Settings fontSize="medium" />
            </IconButton>

            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'lg'}
                onClose={onClose}
                scroll={'paper'}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" textAlign={'center'} variant='h6' gutterBottom>
                    {t('interactive.interactive_node_options')}
                    <span className='close' onClick={onClose}>
                        &times;
                    </span>
                </DialogTitle>
                <DialogContent dividers={true} sx={{ overflow: 'hidden' }}>
                    <Box id="scroll-dialog-description" tabIndex={-1}>
                        <Grid container spacing={2} sx={{ height: '72vh' }}>
                            <Grid
                                item
                                xs={7}
                                className='interactive-modal-container'
                                sx={{
                                    height: '98%',
                                    overflowY: 'scroll',
                                    overflowX: 'hidden',
                                    pr: 2,
                                }}
                            >
                                {/*Options that the user can set to create the interactive message*/}
                                <InteractiveForm
                                    interactiveOptionsValues={interactiveOptionsValues}
                                    interactiveTypeOption={interactiveTypeOption}
                                    interactiveData={interactiveData}
                                    interactiveTypeMedia={interactiveTypeMedia}
                                    setInteractiveOptionsValues={setInteractiveOptionsValues}
                                    setInteractiveData={setInteractiveData}
                                    setInteractiveTypeMedia={setInteractiveTypeMedia}
                                    setInteractiveTypeOption={setInteractiveTypeOption}
                                    enableInactivityOptions={enableInactivityOptions}
                                    setEnableInactivityOptions={setEnableInactivityOptions}
                                    formErrorMessage={formErrorMessage}
                                    formErrorVisible={formErrorVisible}
                                    key={'interactive-form'}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={5}
                                sx={{ height: '98%' }}
                            >
                                <Box
                                    sx={{
                                        height: '98%',
                                        pl: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignContent: 'flex-start',
                                        justifyContent: 'flex-start',
                                        overflowY: 'scroll',
                                        overflowX: 'hidden',
                                    }}
                                    className='interactive-modal-container'
                                >
                                    <Typography
                                        textAlign='left'
                                        variant='h6'
                                        sx={{
                                            fontSize: '1.2rem',
                                            fontWeight: 'bold',
                                            color: '#444',
                                            mb: 1.5,
                                        }}
                                    >
                                        {t('interactive.preview')}
                                    </Typography>
                                    {/*
                                        Preview that the user can see to know how the message will
                                        be arrived to the client
                                    */}
                                    <PreviewMessage
                                        interactiveData={interactiveData}
                                        interactiveOptionsValues={interactiveOptionsValues}
                                        interactiveTypeOption={interactiveTypeOption}
                                        interactiveTypeMedia={interactiveTypeMedia}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('interactive.cancel')}</Button>
                    <Button type="submit" variant="contained" startIcon={<CheckCircle />}>{t('accept')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};