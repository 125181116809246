import { NodeEditor } from 'rete';
import { Node, Conn, Schemes, DiContainer, Module, AreaExtra } from '../rete/types';
import { InputControl } from '../rete/controls';
import { loadFlow } from '../rete/import_flow';
import { AreaPlugin } from 'rete-area-plugin';

export const clearEditor = async(editor: NodeEditor<Schemes>, nodes?: Node[], connection?: Conn[]) => {
    const incomingNodes = nodes || [...editor!.getNodes()];
    const connections = connection || [...editor!.getConnections()];
    for (const c of connections) {
        await editor.removeConnection(c.id);
    }
    for (const n of incomingNodes) {
        await editor.removeNode(n.id);
    }
};

export const animateToNode = (
    area: AreaPlugin<Schemes, AreaExtra>,
    startX: number,
    startY: number,
    targetX: number,
    targetY: number,
    startZoom: number,
    targetZoom: number
) => {
    const duration = 500; // duration in ms
    const startTime = performance.now();

    const animate = (currentTime: number) => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);

        // easing function to smooth the movement
        const easeProgress = 1 - Math.pow(1 - progress, 3);

        const currentX = startX + (targetX - startX) * easeProgress;
        const currentY = startY + (targetY - startY) * easeProgress;
        const currentZoom = startZoom + (targetZoom - startZoom) * easeProgress;

        area.area.zoom(currentZoom);
        area.area.translate(currentX, currentY);

        if (progress < 1) {
            requestAnimationFrame(animate);
        }
    };

    requestAnimationFrame(animate);
};

export const zoomAtNode = async (nodeID: string, di: DiContainer): Promise<void> => {
    if (!nodeID) return;

    const editorNodes = di.editor.getNodes() || [];
    const node = editorNodes.find((node) => (node.controls.node_id as InputControl).options?.value === nodeID);
    if (node) {
        node.selected = true;
        const nodeInArea = di.area.nodeViews.get(node.id);
        if (nodeInArea) {
            const container = di.area.container;
            const area = di.area;
            if (container && area) {
                const rect = container.getBoundingClientRect();

                const startX = area.area.transform?.x ?? 0;
                const startY = area.area.transform?.y ?? 0;
                const startZoom = area.area.transform?.k ?? 1;
                const targetZoom = 1;

                const nodeWidth = nodeInArea.element?.offsetWidth ?? 200;
                const nodeHeight = nodeInArea.element?.offsetHeight ?? 100;
                const targetX = -(nodeInArea.position.x * targetZoom - (rect.width - nodeWidth) / 2);
                const targetY = -(nodeInArea.position.y * targetZoom - (rect.height - nodeHeight) / 2);

                animateToNode(area, startX, startY, targetX, targetY, startZoom, targetZoom);
            }
            di.updateNode(node.id);
        }
    }
};

export const openModule = async (module: Module, di: DiContainer, preserveView: boolean = false): Promise<void> => {
    await clearEditor(di.editor);
    await loadFlow(di, module.content, preserveView, undefined, true);
    di.arranger(false);
};

export const separateHistory = async (di: DiContainer) => {
    if (di.history) {
        di.history.clear();
        di.history.add({
            undo: () => {/* nothing */ },
            redo: () => {/* nothing */ },
        });
        di.history.separate();
    }
};
