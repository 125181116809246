import { NodeEditor } from 'rete';
import { AreaExtensions } from 'rete-area-plugin';
import { SelectorEntity } from 'rete-area-plugin/_types/extensions/selectable';

import { Schemes, DiContainer, Node, ImportNodeData, Module } from './types';
import { export_flow } from './export_flow';
import { loadFlow } from './import_flow';
import { clearEditor, mainParams } from '../util';
import { uuid } from 'short-uuid';
import { ExportCase } from './models/Switch';
import { InputControl } from './controls';

const [, botMxid] = mainParams();

export const deleteNode = (selector: AreaExtensions.Selector<SelectorEntity>, editor: NodeEditor<Schemes>) => {
    selector.entities.forEach(async(entity) => {
        const selectedNode = editor.getNode(entity.id);
        // delete connections
        const connections = editor.getConnections().filter((connection) => {
            return selectedNode.id === connection.source || selectedNode.id === connection.target;
        });

        for (const c of connections) {
            await editor.removeConnection(c.id);
        }
        editor.removeNode(entity.id);
    });
};

export const deleteNodeFromLocalStorage = (selectedNode: Node) => {
    const menuFlow: Module[] = JSON.parse(localStorage.getItem(`${botMxid}`) || '[]');
    const nodeId = (selectedNode.controls!.node_id as InputControl)?.options?.value || '';
    const moduleName = JSON.parse(localStorage.getItem(`selected_module-${botMxid}`) || '');
    const modules = menuFlow.map(module =>
        module.label === moduleName
            ? { ...module, content: module.content.filter(node => node.id !== nodeId) }
            : module
    );
    localStorage.setItem(`${botMxid}`, JSON.stringify(modules));
};

const fix_cases = (node: ImportNodeData, uuid: string): ExportCase[] => {
    return (node.cases || []).map((c) => {
        return {
            ...c,
            id: c.id,
            o_connection: `${c.o_connection.substring(0, 12)}_${uuid}`,
        };
    });
};

const changeNodeNamesAtCopy = (nodes: ImportNodeData[]): ImportNodeData[] => {
    const new_uuid = uuid().split('-')[0];
    return nodes.map((node) => {
        const nodeToPaste = {
            ...node,
            id: `${node.id.substring(0, 12)}_${new_uuid}`,
            name: `${node.name.substring(0, 12)} ${new_uuid}`,
        };

        if (node.cases) {
            nodeToPaste.cases = fix_cases(node, new_uuid) as ExportCase[];
        } else if (node.o_connection) {
            nodeToPaste.o_connection = `${node.o_connection.substring(0, 12)}_${new_uuid}`;
        }
        return nodeToPaste;
    });
};

export const copyNode = (
    selector: AreaExtensions.Selector<SelectorEntity>, di: DiContainer, change_names: boolean = true
): Node[] => {
    const selectedNodes: Node[] = [];
    selector.entities.forEach((entity) => {
        const selectedNode = di.editor.getNode(entity.id);
        if (selectedNode === undefined) return;
        selectedNodes.push(di.editor.getNode(entity.id));
    });

    let clipboard = export_flow('', di, selectedNodes) as ImportNodeData[];
    if (change_names) clipboard = changeNodeNamesAtCopy(clipboard);
    localStorage.setItem('clipboard', JSON.stringify(clipboard));
    return selectedNodes;
};

export const cutNode = (selector: AreaExtensions.Selector<SelectorEntity>, di: DiContainer) => {
    const copiedNodes = copyNode(selector, di, false);
    const connections = di.editor.getConnections().filter((connection) => {
        return copiedNodes.some((node) => node.id === connection.source || node.id === connection.target);
    });
    clearEditor(di.editor, copiedNodes, connections);
};

export const pasteNode = (di: DiContainer) => {
    const clipboard = localStorage.getItem('clipboard');
    if (clipboard) {
        const parsed: ImportNodeData[] = JSON.parse(clipboard);
        loadFlow(di, parsed, true, undefined, false, true);
    }
};