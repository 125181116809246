import React, { useState, useEffect, useRef } from 'react';
import { ClassicPreset as Classic } from 'rete';
import '../Modal.css';
import {
    TextField,
    Typography,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Switch,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Box,
    Alert,
} from '@mui/material';
import { Settings, Cancel, WarningAmber, CheckCircle } from '@mui/icons-material';
import { DiContainer, NodeID } from '../../../types';
import { MediaData } from '../../../models/Media';
import placeholder from '../../../../assets/img/placeholder.jpg';
import { useTranslation } from 'react-i18next';
import { clearMessage, getNextNodeName, nodeNameAlreadyExists } from '../../../../util';
import JinjaCodeMirrorEditor from '../../../components/JinjaEditor';
import TextEditor from '../../../components/TextEditor';
import { byMessage } from '../../../components/utils/configToolbarEditor';

declare type MediaModalControlOptions = {
    modalInfo?: MediaData;
    nodeId: NodeID;
    di: DiContainer;
    socket: Classic.Socket;
};

export class MediaModalControl extends Classic.Control {
    constructor(public options?: MediaModalControlOptions) {
        super();
    }
}

export const MediaModal = (props: { data: MediaModalControl }): JSX.Element => {
    const { t } = useTranslation();
    const [mediaData, setMediaData] = useState<MediaData>({
        name: getNextNodeName('Media', 'media', props.data.options?.di.editor!),
        message_type: 'm.image',
        text: '',
        url: '',
        info: {
            mimetype: '',
            size: '',
            height: '',
            width: '',
        },
    });
    const [formErrorVisible, setFormErrorVisible] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [preview, setPreview] = useState<string>(placeholder);
    const [enableMediaOptions, setEnableMediaOptions] = useState(false);
    const [open, setOpen] = useState(false);

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    useEffect(() => {
        if (props.data.options?.modalInfo && !open) {
            setMediaData(props.data.options.modalInfo);
            setPreview(props.data.options.modalInfo.url);
        }
    }, [mediaData, open]);

    const onOpen = () => () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        if (nodeNameAlreadyExists(mediaData.name, props.data.options?.nodeId!, props.data.options?.di.editor!)) {
            showErrorMessage(t('media.name_already_exists'));
            return;
        }
        if (mediaData.text === '') {
            showErrorMessage(t('media.text_required'));
            return;
        }
        if (mediaData.url === '') {
            showErrorMessage(t('media.url_required'));
            return;
        }

        props.data.options!.modalInfo = mediaData;
        props.data.options?.di.updateNode(props.data.options!.nodeId.reteNodeId);
        onClose();
    };

    return (
        <div>
            <IconButton
                aria-label="Node Options"
                size="small"
                className='open-button'
                onPointerDown={(e): void => e.stopPropagation()}
                onClick={onOpen()}
            >
                <Settings fontSize="medium" />
            </IconButton>

            <Dialog
                open={open}
                onClose={onClose}
                fullWidth={true}
                maxWidth={'md'}
                scroll={'paper'}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title"  textAlign={'center'} variant='h6' gutterBottom>
                    {t('media.media_node_options')}
                    <span className='close' onClick={onClose}>
                        &times;
                    </span>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Box
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Typography variant="caption" display="block" gutterBottom>
                            {t('media.required_fields')}
                        </Typography>
                        <br />
                        <Grid container spacing={2} sx={{ ml: 0.3 }}>
                            <TextField
                                label={t('media.name')}
                                id='node-name'
                                value={mediaData.name}
                                required
                                onChange={(e): void => setMediaData({ ...mediaData, name: e.target.value })}
                                sx={{ m: 1, width: '47%' }}
                                size="small"
                            />
                            <FormControl sx={{ m: 1, width: '47%' }}>
                                <InputLabel id='input-type-label'>{t('media.message_type')}</InputLabel>
                                <Select
                                    labelId='input-type-label'
                                    id='input-type'
                                    value={mediaData.message_type}
                                    required
                                    onChange={(e): void => setMediaData({ ...mediaData, message_type: e.target.value })}
                                    label={t('media.message_type')}
                                    size="small"
                                >
                                    <MenuItem value={'m.image'}>m.image</MenuItem>
                                    <MenuItem value={'m.video'}>m.video</MenuItem>
                                    <MenuItem value={'m.audio'}>m.audio</MenuItem>
                                    <MenuItem value={'m.file'}>m.file</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <JinjaCodeMirrorEditor
                            label={'media.url'}
                            pr={'2%'}
                            jinjaExpression={mediaData.url}
                            onChangeFunction={(value: string): void => {
                                setMediaData({ ...mediaData, url: value });
                                if (value === '') {
                                    setPreview(placeholder);
                                    return;
                                }
                                setPreview(value);
                            }}
                        />
                        <div className='contentMedia'>
                            {mediaData.message_type === 'm.image' &&
                                <img src={preview} alt='Media' className='mediaScale' style={{ width: 'auto', height: 'auto' }} />
                            }
                            {mediaData.message_type === 'm.video' &&
                                <video className='mediaScale' controls style={{ width: 'auto', height: 'auto' }}>
                                    <source src={preview} type="video/mp4"/>
                                </video>
                            }
                        </div>
                        <Typography sx={{ ml: 0.3 }} textAlign='left' variant='subtitle1'>{t('media.text')}</Typography>
                        <TextEditor
                            value={clearMessage(mediaData.text)}
                            toolbarOptions={byMessage}
                            activeMarkdown={true}
                            onChange={(value: string): void => setMediaData({ ...mediaData, text: value })}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={11}>
                                <Typography textAlign='left' variant='subtitle1'>{t('media.advanced_options')}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <div className='float-right'>
                                    <Switch
                                        checked={enableMediaOptions}
                                        color='primary'
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                        onChange={(): void => setEnableMediaOptions(!enableMediaOptions)}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <TextField
                            label='Mimetype'
                            id='mimetype'
                            value={mediaData.info?.mimetype}
                            onChange={(e): void => setMediaData({ ...mediaData, info: { ...mediaData.info, mimetype: e.target.value } })}
                            sx={{ m: 1, width: '48%' }}
                            disabled={!enableMediaOptions}
                            size="small"
                        />
                        <TextField
                            label={t('media.size')}
                            id='size'
                            value={mediaData.info?.size}
                            onChange={(e): void => setMediaData({ ...mediaData, info: { ...mediaData.info, size: e.target.value } })}
                            sx={{ m: 1, width: '48%' }}
                            disabled={!enableMediaOptions}
                            size="small"
                        />
                        <TextField
                            label={t('media.height')}
                            id='height'
                            value={mediaData.info?.height}
                            onChange={(e): void => setMediaData({ ...mediaData, info: { ...mediaData.info, height: e.target.value } })}
                            sx={{ m: 1, width: '48%' }}
                            disabled={!enableMediaOptions}
                            size="small"
                        />
                        <TextField
                            label={t('media.width')}
                            id='width'
                            value={mediaData.info?.width}
                            onChange={(e): void => setMediaData({ ...mediaData, info: { ...mediaData.info, width: e.target.value } })}
                            sx={{ m: 1, width: '48%' }}
                            disabled={!enableMediaOptions}
                            size="small"
                        />
                        <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            {formErrorVisible &&
                                <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                                    {formErrorMessage}
                                </Alert>
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('media.cancel')}</Button>
                    <Button type="submit" variant="contained" startIcon={<CheckCircle />}>{t('accept')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};