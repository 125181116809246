import { useEffect, useRef, useState } from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Box,
    Alert,
} from '@mui/material';
import { Cancel, CheckCircle, WarningAmber } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Module } from '../../rete/types';

type RemoveModuleModalProps = {
    open: boolean;
    models: Module[];
    onClose: () => void;
    remove: (moduleValue: string) => void;
};

export const RemoveModuleModal = (props: RemoveModuleModalProps): JSX.Element => {
    const [moduleToRemove, setModuleToRemove] = useState('');
    const [deleteVerification, setDeleteVerification] = useState('');
    const [formErrorVisible, setFormErrorVisible] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');

    const { t } = useTranslation();

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (props.open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [props.open]);

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const cleanStates = (): void => {
        setModuleToRemove('');
        setDeleteVerification('');
    };

    const removeModule = (): void => {
        if (deleteVerification.toLocaleUpperCase() != 'DELETE'){
            showErrorMessage(t('remove_module_modal.delete_verification_error'));
            setDeleteVerification('');
            return;
        };
        props.remove(moduleToRemove);
        cleanStates();
    };

    return(
        <Dialog
            open={props.open}
            onClose={props.onClose}
            scroll={'paper'}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    removeModule();
                },
            }}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitle id="scroll-dialog-title" textAlign={'center'} variant='h6' gutterBottom>
                {t('remove_module_modal.remove_module')}
                <span className='close' onClick={props.onClose}>
                    &times;
                </span>
            </DialogTitle>
            <DialogContent dividers={true}>
                <Box
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                    <FormControl sx={{ m: 1, width: '98.5%' }} size='small'>
                        <InputLabel id='module'>{t('remove_module_modal.module')}</InputLabel>
                        <Select
                            labelId='module'
                            id='module'
                            value={moduleToRemove}
                            required
                            onChange={(e): void => setModuleToRemove(e.target.value)}
                            label='Module'
                        >
                            {props.models.map((model) => {
                                if (model.label == 'Main') return;
                                return <MenuItem key={model.label} value={model.label}>{model.label}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                    <TextField
                        label={t('remove_module_modal.enter_the_word_delete_to_confirm')}
                        id='delete-verification'
                        value={deleteVerification}
                        required
                        onChange={(e): void => setDeleteVerification(e.target.value)}
                        sx={{ m: 1, width: '98.5%' }}
                        size="small"
                    />
                    <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        {formErrorVisible &&
                            <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                                {formErrorMessage}
                            </Alert>
                        }
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" startIcon={<Cancel />} onClick={props.onClose}>{t('remove_module_modal.cancel')}</Button>
                <Button type="submit" variant="contained" startIcon={<CheckCircle />}>{t('accept')}</Button>
            </DialogActions>
        </Dialog>
    );
};