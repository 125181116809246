import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { t } from 'i18next';
import { Add, ExpandLess, ExpandMore } from '@mui/icons-material';
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import { CaseComponent } from '../../controls/modal/switch_modal/CaseComponent';
import { FormData } from '../../models/Form';
import { Case } from '../../models/Switch';
import { reorder } from '../utils/reorderOnDragList';

const FormCases = (
    props: {
        formData: FormData;
        setFormData: (formData: FormData) => void;
        enableInactivityOptions: boolean;
        enableValidationFail: boolean;
    }
): JSX.Element => {
    const [expanded, setExpanded] = useState<boolean>(false);

    useEffect(() => {
        if (
            props.enableInactivityOptions &&
            !props.formData.cases.find((item) => item.id === 'timeout')
        ) {
            addNewCase('timeout');
        } else if (!props.enableInactivityOptions) {
            const case_index = props.formData.cases.findIndex((item) => item.id === 'timeout');
            if (case_index >= 0) removeCase(case_index);
        }
    }, [props.enableInactivityOptions]);

    useEffect(() => {
        if ( props.enableValidationFail &&
            !props.formData.cases.find((item) => item.id === 'attempt_exceeded')
        ) {
            addNewCase('attempt_exceeded');
        } else if (!props.enableValidationFail){
            const case_index = props.formData.cases.findIndex((item) => item.id === 'attempt_exceeded');
            if (case_index >= 0) removeCase(case_index);
        }
    }, [props.enableValidationFail]);

    const removeCase = (index: number): void => {
        const newCases = [...props.formData.cases];
        newCases.splice(index, 1);
        props.setFormData({ ...props.formData, cases: newCases });
    };

    const saveCase = (index: number, key: 'id' | 'o_connection', value: string): void => {
        const newCases = [...props.formData.cases];
        newCases[index][key] = value;
        props.setFormData({ ...props.formData, cases: newCases });
    };

    const addVariable = (caseIndex: number): void => {
        const newCases = [...props.formData.cases];
        if (newCases[caseIndex].variables) {
            newCases[caseIndex].variables?.push({ key: '', value: '' });
        }
        props.setFormData({ ...props.formData, cases: newCases });
    };

    const saveVariable = (index: number, caseIndex: number, key: 'key' | 'value', value: string): void => {
        const newCases = [...props.formData.cases];
        if (newCases[caseIndex].variables != undefined) {
            (newCases[caseIndex].variables ?? [])[index][key] = value;
        }
        props.setFormData({ ...props.formData, cases: newCases });
    };

    const removeVariable = (caseIndex: number, index: number): void => {
        const newCases = [...props.formData.cases];
        if (newCases[caseIndex].variables) {
            newCases[caseIndex].variables?.splice(index, 1);
        }
        props.setFormData({ ...props.formData, cases: newCases });
    };

    const handleExpandClick = () => {
        setExpanded((prev) => !prev);
    };

    const addNewCase = (case_id: string = ''): void => {
        props.setFormData({
            ...props.formData,
            cases: [...props.formData.cases, { id: case_id, o_connection: '', variables: [] }],
        });
    };

    const onDragEnd = (result: DropResult): void => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const cases:
            Case[] = reorder(
                props.formData.cases,
                result.source.index,
                result.destination.index
            );

        props.setFormData({
            ...props.formData,
            cases: [...cases],
        });
    };

    return (
        <Grid container spacing={0} className='section-container'>
            <Grid container spacing={0} sx={{ mb: 1 }}>
                <Grid item xs={10} >
                    <Typography
                        textAlign="left"
                        variant='h6'
                        sx={{ ml: 1, fontSize: '1.2rem' }}
                    >
                        {t('form.cases')}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Box className='round-button' textAlign={'end'}>
                        <Tooltip title={t('case_component.tooltip_add_case')}>
                            <IconButton
                                aria-label="round-button"
                                onClick={() => addNewCase()}
                                sx={{ ml: 4 }}
                            >
                                <Add sx={{ fontSize: '1rem' }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Grid>
                <Grid item xs={1} justifySelf={'start'}>
                    <Box className='expand-case-option' textAlign={'start'}>
                        <IconButton
                            aria-label='expand-form-item'
                            onClick={() => { handleExpandClick(); }}
                            sx={{ ml: 2 }}
                        >
                            {!expanded && <ExpandMore sx={{ fontSize: '1rem' }} />}
                            {expanded && <ExpandLess sx={{ fontSize: '1rem' }} />}
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppableFormCases'>
                    {(provided: DroppableProvided) => (
                        <Grid
                            container
                            spacing={2}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {
                                props.formData.cases.map((item, index) => (
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ ml: 2, mr: 2 }}
                                        key={`case-${item.id}-${index}`}
                                    >
                                        <CaseComponent
                                            key={`case-component-${item.id}-${index}`}
                                            data={item}
                                            index={index}
                                            removeCase={removeCase}
                                            saveCase={saveCase}
                                            addVariable={addVariable}
                                            removeVariable={removeVariable}
                                            saveVariable={saveVariable}
                                            expanded={expanded}
                                            textFileWidth={'46.2%'}
                                        />
                                    </Grid>
                                ))
                            }
                            {provided.placeholder}
                        </Grid>

                    )}
                </Droppable>
            </DragDropContext>
        </Grid>
    );
};

export default FormCases;