import React, { useMemo, useState } from 'react';
import { ClassicPreset as Classic } from 'rete';
import '../Modal.css';
import {
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Box,
    Alert,
    Grid,
} from '@mui/material';
import { Settings, Cancel, WarningAmber, CheckCircle } from '@mui/icons-material';
import { DiContainer, NodeID } from '../../../types';
import { FormData } from '../../../models/Form';
import { checkJinja2Syntaxis, getNextNodeName, nodeNameAlreadyExists } from '../../../../util';
import { t } from 'i18next';
import FormTemplateData from '../../../components/form/FormTemplateData';
import FormInactivityOptions from '../../../components/form/FormInactivityOptions';
import FormCases from '../../../components/form/FormCases';
import FormVariablesContainer from '../../../components/form/FormVariablesContainer';

declare type FormModalControlOptions = {
    modalInfo?: FormData;
    nodeId: NodeID;
    di: DiContainer;
    socket: Classic.Socket;
};

export class FormModalControl extends Classic.Control {
    constructor(public options?: FormModalControlOptions) {
        super();
    }
}

export const FormModal = (props: { data: FormModalControl }): JSX.Element => {
    const [formData, setFormData] = useState<FormData>({
        name: getNextNodeName('Form', 'form', props.data.options?.di.editor!),
        template_name: '',
        language: 'es',
        body_variables: [''],
        header_variables: [''],
        button_variables: [''],
        validation_fail: {
            message: '',
            attempts: '',
        },
        inactivity_options: {},
        variable: '',
        cases: [{ id: 'default', o_connection: '', variables: [] }],

    });
    const [open, setOpen] = useState(false);
    const [formErrorVisible, setFormErrorVisible] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [enableInactivityOptions, setEnableInactivityOptions] = useState(false);
    const [enableValidationFail, setEnableValidationFail] = useState(formData.validation_fail?.attempts != '');

    useMemo(() => {
        if (props.data.options?.modalInfo && !open) {
            let node_data_copy = JSON.parse(JSON.stringify(props.data.options.modalInfo));
            node_data_copy = {
                ...node_data_copy,
                form_data: {
                    ...node_data_copy.form_data,
                },
            };
            setFormData(node_data_copy);
        }
    }, [open]);

    const onOpen = () => () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const checkAddConnection = (value: string): boolean => {
        // Check if the value of the connection is a jinja2 variable or the string 'finish'
        // It is used to determine if the connection should be added to the node
        return checkJinja2Syntaxis(value) || value === 'finish';
    };

    const updateOutputConnection = (): void => {
        const di = props.data.options!.di;
        const node = di.editor.getNode(props.data.options!.nodeId!.reteNodeId);
        const socket = props.data.options!.socket;
        const outputIDs: string[] = [];
        const casesID: string[] = [];

        if (node.outputs) {
            for (const output in node.outputs) {
                outputIDs.push(output);
            }
        }

        formData.cases.forEach((item) => {
            if (!checkAddConnection(item.o_connection)) {
                casesID.push(item.id);
            }
        });

        outputIDs.map((output) => {
            if (!casesID.includes(output)) {
                node.removeOutput(output);
            }
        });

        formData.cases.forEach((item) => {
            if (!outputIDs.includes(item.id) && !checkAddConnection(item.o_connection)) {
                node.addOutput(item.id, new Classic.Output(socket, item.id, false));
            }
        });
        di.updateNode(props.data.options!.nodeId!.reteNodeId);
    };

    const updateNodeHeigth = (): void => {
        const nodeHeigth = 180 + (formData.cases.length * 30);
        const editor_node = props.data.options!.di.editor.getNode(props.data.options!.nodeId!.reteNodeId);
        editor_node.height = nodeHeigth;
        props.data.options!.di.updateNode(props.data.options!.nodeId!.reteNodeId);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (formData?.template_name && formData?.template_name.length > 512) {
            showErrorMessage(t('form.name_field_too_long'));
            return;
        } else if (
            nodeNameAlreadyExists(
                formData.name, props.data.options?.nodeId!, props.data.options?.di.editor!
            )
        ) {
            showErrorMessage(t('form.name_already_exists'));
            return;
        }

        if (!formData.language || formData.language.length == 0){
            showErrorMessage(t('form.language_required'));
            return;
        }

        props.data.options!.modalInfo = {
            name: formData.name,
            template_name: formData.template_name,
            language: formData.language,
            body_variables: formData.body_variables,
            header_variables: formData.header_variables,
            button_variables: formData.button_variables,
            inactivity_options: formData.inactivity_options,
            validation: formData.validation,
            validation_fail: formData.validation_fail,
            variable: formData.variable,
            cases: formData.cases,
        };

        props.data.options!.di.updateControl(props.data.id);
        props.data.options?.di.updateControl(props.data.id);
        updateOutputConnection();
        updateNodeHeigth();
        onClose();
    };

    return (
        <>
            <IconButton
                aria-label="Node Options"
                size="small"
                className='open-button'
                onPointerDown={(e): void => e.stopPropagation()}
                onClick={onOpen()}
            >
                <Settings fontSize="medium" />
            </IconButton>

            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                onClose={onClose}
                scroll={'paper'}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" textAlign={'center'} variant='h6' gutterBottom>
                    {t('form.form_node_options')}
                    <span className='close' onClick={onClose}>
                        &times;
                    </span>
                </DialogTitle>
                <DialogContent dividers={true} >
                    <Box id="scroll-dialog-description" tabIndex={-1}>
                        <Grid container >
                            <FormTemplateData
                                formData={formData}
                                setFormData={setFormData}
                                setEnableValidationFail={setEnableValidationFail}
                            />
                            <FormInactivityOptions
                                enableInactivityOptions={enableInactivityOptions}
                                setEnableInactivityOptions={setEnableInactivityOptions}
                                formData={formData}
                                setFormData={setFormData}
                            />
                            <FormVariablesContainer
                                formData={formData}
                                setFormData={setFormData}
                                variable_type='header'
                            />
                            <FormVariablesContainer
                                formData={formData}
                                setFormData={setFormData}
                                variable_type='body'
                            />
                            <FormVariablesContainer
                                formData={formData}
                                setFormData={setFormData}
                                variable_type='button'
                            />
                            <FormCases
                                formData={formData}
                                setFormData={setFormData}
                                enableInactivityOptions={enableInactivityOptions}
                                enableValidationFail={enableValidationFail}
                            />

                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('interactive.cancel')}</Button>
                    <Button type="submit" variant="contained" startIcon={<CheckCircle />}>{t('accept')}</Button>
                </DialogActions>
                <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    {formErrorVisible &&
                        <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                            {formErrorMessage}
                        </Alert>
                    }
                </Box>
            </Dialog>
        </>
    );
};