import { useState, useEffect } from 'react';

import { Alert, Box, IconButton, Tooltip, Typography, TextField, InputAdornment, Tab, Tabs, TabProps, Stack } from '@mui/material';
import { Add, AdsClick, Clear, ControlCamera, Remove, Edit } from '@mui/icons-material';
import { Module, NodeData } from '../../rete/types';
import { NewModuleModal } from './NewModuleModal';
import { RemoveModuleModal } from './RemoveModuleModal';
import { slugify_text, mainParams, clearEditor, openModule, animateToNode } from '../../util';
import useLocalStorage from '../../hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';

import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SearchIcon from '@mui/icons-material/Search';
import LassoSelectIcon from '@mui/icons-material/GestureOutlined';
import MarqueeSelectIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';

import { ExportButton, FlowVariables, FullScreen, SearchNode } from '../../components';
import { createEditor as editor } from '../../rete/editor';
import { useRete } from 'rete-react-plugin';
import { FlowService } from '../../services';
import { export_flow } from '../../rete/export_flow';
import { tabsClasses } from '@mui/material/Tabs';
import { RestoreFlow } from '../RestoreFlow';
import { DndContext, useSensor, useSensors, PointerSensor, closestCenter, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, arrayMove, horizontalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { AutoArrangeButton } from '../AutoArrangeButton';
import { KeyboardShortcuts } from '../KeyboardShortcuts';
import { InputControl } from '../../rete/controls';
import { EditModuleModal } from './EditModuleModal';

const drawerWidth = 240;
const [menuflowDomain, botMxid] = mainParams();
const flowService = new FlowService(menuflowDomain, botMxid);

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

/**
 * Main component that manages the user interface for the list of modules.
 * Uses a drawer to display navigation options and buttons for exporting, managing flow variables and full screen.
 * export, manage flow variables and full screen.
 */
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{ open?: boolean }>(({ theme }) => ({
    flexGrow: 2,
    padding: 0,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    variants: [
        {
            props: ({ open }) => open,
            style: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: 0,
            },
        },
    ],
}));

/**
 * Styled component for the application's AppBar.
 * Manages the transition and width adjustments when the drawer is opened or closed.
 */
const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })<AppBarProps>(({ theme }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    variants: [
        {
            props: ({ open }) => open,
            style: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: `${drawerWidth}px`,
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
        },
    ],
}));

/**
 * Styled component for the header of the drawer.
 * Ensures proper alignment and spacing for the content within the drawer.
 * It also includes necessary styles to position content below the app bar.
 */
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    minHeight: '40px !important',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const AntTab = styled((props: TabProps & { selected?: boolean }) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        minWidth: 0,
        maxHeight: '32px',
        padding: '0 12px',
        minHeight: '28px',
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        color: 'rgba(0, 0, 0, 0.85)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
            backgroundColor: 'rgba(64, 169, 255, 0.05)',
            borderRadius: '8px 8px 0 0',
        },
        '&.Mui-selected': {
            color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium,
            backgroundColor: 'rgba(24, 143, 255, 0.1)',
            borderRadius: '8px 8px 0 0',
            transition: 'all 0.2s ease-in-out',
            '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '2px',
                backgroundColor: '#1890ff',
            },
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#d1eaff',
        },
        maxWidth: '200px',
        '& .MuiTab-wrapper': {
            width: '100%',
            display: 'inline-flex',
            alignItems: 'center',
        },
        '& .MuiTab-labelIcon': {
            minHeight: '28px',
        },
        '& .MuiBox-root': {
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    }),
);

interface SortableTabProps {
    tab: Module;
    handleTabClose: (moduleLabel: string) => void;
    isChanged: boolean;
    handleTabChange: (event: React.SyntheticEvent, value: number) => void;
    selectedModule: string;
    openTabs: Module[];
}

const SortableTab = ({ tab, handleTabClose, isChanged, handleTabChange, selectedModule, openTabs }: SortableTabProps) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: tab.label });
    const { t } = useTranslation();

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners} className="sortable-tab-wrapper">
            <AntTab
                onClick={(e) => handleTabChange(e, openTabs.findIndex(t => t.label === tab.label))}
                label={
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        overflow: 'hidden',
                    }}>
                        {isChanged && <Tooltip title={t('changes in module') + ' ' + tab.label}>
                            <span className='module-tab-marker-changes-indicator'></span>
                        </Tooltip>}
                        <Typography
                            noWrap
                            component="span"
                            sx={{ flex: 1, minWidth: 0 }}
                            fontSize={'12px'}
                        >
                            <Tooltip title={tab.label} placement="bottom">
                                <span>
                                    {tab.label}
                                </span>
                            </Tooltip>
                        </Typography>
                        <div className='close-tab-button' onClick={(e) => {
                            e.stopPropagation();
                            handleTabClose(tab.label);
                        }}>
                            <Clear fontSize="small" sx={{ width: '16px', height: '16px' }} />
                        </div>
                    </Box>
                }
                value={openTabs.findIndex(t => t.label === tab.label)}
                selected={tab.label === selectedModule}
            />
        </div>
    );
};

export const ModuleList = (): JSX.Element => {
    const [ref, editorProps] = useRete(editor);
    const [showAlert, setshowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [modules, setModules] = useLocalStorage<Module[]>(botMxid, []);
    const [selectedModule, setSelectedModule] = useLocalStorage(`selected_module-${botMxid}`, 'Main');
    const [flowId, setFlowId] = useLocalStorage<number | undefined>(`flow_id-${botMxid}`, undefined);
    const [updateComponentBackupFlow, setUpdateComponentBackupFlow] = useState(false);

    const [openNewModal, setOpenNewModal] = useState(false);
    const [openRemoveModal, setOpenRemoveModal] = useState(false);
    const [module, setModule] = useLocalStorage<Module[]>(botMxid, []);
    const [changedModules, setChangedModules] = useLocalStorage<string[]>(`changedModules-${botMxid}`, []);
    const { t } = useTranslation();

    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [openTabs, setOpenTabs] = useState<Module[]>([]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const [selectionShape, setSelectionShape] = useLocalStorage<'lasso' | 'marquee'>(`selection-shape-${botMxid}`, 'lasso');
    const [selectionButton, setSelectionButton] = useLocalStorage<0 | 1>(`selection-button-${botMxid}`, 1);

    const [, setCurrentZoom] = useLocalStorage('currentZoom', 1);
    const [, setCurrentX] = useLocalStorage('currentX', 0);
    const [, setCurrentY] = useLocalStorage('currentY', 0);

    const [previousSelectionButton, setPreviousSelectionButton] = useState<0 | 1>(1);

    const [moduleViewStates, setModuleViewStates] = useState<Record<string, {
        zoom: number;
        x: number;
        y: number;
        nodePositions: Array<{
            id: string;
            position: { x: number; y: number } | null;
        }>;
    }>>({});

    const [openEditModal, setOpenEditModal] = useState(false);
    const [moduleToEdit, setModuleToEdit] = useState('');

    const filteredModules = modules.filter((module) => {
        return module.label.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const addTab = (module: Module) => {
        if (!openTabs.some(tab => tab.label === module.label)) {
            setOpenTabs([...openTabs, module]);
        }
    };

    const removeTab = (moduleLabel: string) => {
        const newOpenTabs = openTabs.filter(tab => tab.label !== moduleLabel);
        setOpenTabs(newOpenTabs);

        if (selectedModule === moduleLabel && newOpenTabs.length > 0) {
            const lastTab = newOpenTabs[newOpenTabs.length - 1];
            updateSelectedModule(lastTab.label);
        }
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        const newModuleLabel = openTabs[newValue].label;
        if (checkModuleChanges(selectedModule)) saveModules(selectedModule);
        updateSelectedModule(newModuleLabel);
        setSelectedTabIndex(newValue);
    };

    const checkModuleChanges = (moduleName: string): boolean => {
        const module = modules.find((mod) => mod.label === moduleName);
        const nodesInModule = module?.content;
        const nodesInEditor = editorProps?.di.editor.getNodes() || [];

        const hasChanges = nodesInModule?.length !== nodesInEditor.length;
        if (hasChanges) {
            markModuleAsChanged(moduleName);
        }
        return hasChanges;
    };

    const updateSelectedModule = (module: string): void => {
        // Save the state of the current module before changing
        if (selectedModule && editorProps?.di) {
            const currentTransform = editorProps.di.area.area.transform;
            const currentNodes = editorProps.di.editor.getNodes();
            const nodePositions = currentNodes
                .map(node => {
                    const view = editorProps.di.area.nodeViews.get(node.id);
                    const nodeId = (node.controls!.node_id as InputControl)?.options?.value;

                    // Only include nodes that have a valid ID and a position
                    if (nodeId && view) {
                        return {
                            id: nodeId,
                            position: { x: view.position.x, y: view.position.y },
                        };
                    }
                    return null;
                })
                .filter((node): node is { id: string; position: { x: number; y: number } } =>
                    node !== null
                );

            setModuleViewStates(prev => ({
                ...prev,
                [selectedModule]: {
                    zoom: currentTransform?.k ?? 1,
                    x: currentTransform?.x ?? 0,
                    y: currentTransform?.y ?? 0,
                    nodePositions: nodePositions,
                },
            }));
        }

        // Update the selected module
        checkModuleChanges(selectedModule);
        setSelectedModule(module);
        const selectedModuleData = modules.find((mod) => mod.label === module);

        if (selectedModuleData) {
            const viewState = moduleViewStates[module];
            if (viewState && editorProps?.di) {
                clearEditor(editorProps.di.editor).then(() => {
                    openModule(selectedModuleData, editorProps.di, true).then(() => {
                        // Wait a moment for the nodes to be created
                        setTimeout(() => {
                            // Restore node positions
                            viewState.nodePositions.forEach(nodePos => {
                                if (nodePos.position) {
                                    const nodes = editorProps.di.editor.getNodes();
                                    const node = nodes.find(n =>
                                        (n.controls!.node_id as InputControl)?.options?.value === nodePos.id
                                    );
                                    if (node) {
                                        const view = editorProps.di.area.nodeViews.get(node.id);
                                        if (view) {
                                            view.translate(nodePos.position.x, nodePos.position.y);
                                        }
                                    }
                                }
                            });

                            // Restaurar la vista del área
                            animateToNode(
                                editorProps.di.area!,
                                0,
                                0,
                                viewState.x,
                                viewState.y,
                                1,
                                viewState.zoom,
                            );
                        }, 100);
                    });
                });
            } else {
                openModule(selectedModuleData, editorProps!.di);
            }
            addTab(selectedModuleData);
            const tabIndex = openTabs.findIndex(tab => tab.label === module);
            if (tabIndex !== -1) {
                setSelectedTabIndex(tabIndex);
            }
        }
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const saveModule = (moduleName: string): void => {
        const newModule = { label: moduleName, content: [] };
        if (!module.some((mod) => mod.label === moduleName)) {
            setModule([...module, newModule]);
        }
        setSelectedModule(moduleName);
        setOpenNewModal(false);

        // Clean the editor and open the new module
        clearEditor(editorProps!.di.editor).then(() => {
            openModule(newModule, editorProps!.di);
            addTab(newModule);
            updateSelectedModule(moduleName);
        });
    };

    const removeModule = (moduleValue: string): void => {
        const cleanModules = module.filter((module: Module) => slugify_text(module.label) !== slugify_text(moduleValue));
        setModule(cleanModules);
        setOpenRemoveModal(false);

        removeTab(moduleValue);
    };

    const saveModules = (moduleName: string): void => {
        //save current zoom and coordinates of all nodes in the area
        setCurrentZoom(editorProps!.di.area.area.transform?.k ?? 1);
        setCurrentX(editorProps!.di.area.area.transform?.x ?? 0);
        setCurrentY(editorProps!.di.area.area.transform?.y ?? 0);

        const exportedNodes = export_flow(moduleName, editorProps!.di);
        const modulesCopy = modules.slice();
        const moduleIndex = modulesCopy.findIndex((module: Module) => module.label === moduleName);
        modulesCopy[moduleIndex] = { label: moduleName, content: exportedNodes };
        setModules([...modulesCopy]);
    };

    const showMainAlert = (message: string): void => {
        setAlertMessage(message);
        setshowAlert(true);
        setTimeout(() => setshowAlert(false), 3000);
    };

    function generateModules(nodes: NodeData[]): Module[] {
        const grouped = nodes.reduce((result: Module[], item: NodeData) => {
            const keyValue = item['module'] || 'Main';

            let group = result.find(g => g.label === keyValue);
            if (!group) {
                group = { label: keyValue, content: [] };
                result.push(group);
            }

            group.content.push(item);
            return result;
        }, [] as Module[]);

        return grouped.length > 0 ? grouped : [{ 'label': 'Main', 'content': [] }];
    };

    const getModules = async (): Promise<Module[]> => {
        const [flow, status] = await flowService.getFlow();
        if (status !== 200) {
            console.error('Error loading flow');
            return [];
        }
        setFlowId(flow.id);

        const nodes = flow.flow.menu.nodes;
        return generateModules(nodes);
    };

    useEffect(() => {
        const retriveModules = async () => {
            const modulesRetrieved = await getModules();
            const uniqueModules = modulesRetrieved.filter((mod, index, self) =>
                index === self.findIndex((m) => m.label === mod.label)
            );
            setModules(uniqueModules);

            // Make sure that the Main module exists
            const mainModule = uniqueModules.find((module: Module) => module.label === 'Main');
            if (mainModule) {
                setSelectedModule('Main');
                setSelectedTabIndex(0);
                openModule(mainModule, editorProps!.di);
                setOpenTabs([mainModule]);
            } else if (uniqueModules.length > 0) {
                const firstModule = uniqueModules[0];
                setSelectedModule(firstModule.label);
                setSelectedTabIndex(0);
                openModule(firstModule, editorProps!.di);
                setOpenTabs([firstModule]);
            }
        };

        if (editorProps) retriveModules();
    }, [editorProps]);

    useEffect(() => {
        if (editorProps) {
            // If the selected module change in another location of the app, add the tab if it is not already open
            const isTabOpen = openTabs.find((tab) => tab.label === selectedModule);
            if (!isTabOpen) {
                const module = modules.find((mod) => mod.label === selectedModule);
                if (module) addTab(module);
            }

            const updateModuleSelection = () => {
                // Remover la clase de todos los elementos
                document.querySelectorAll('.moduleTabButton').forEach((element) => {
                    element.classList.remove('selectedModuleTabButton');
                });

                // Añadir la clase al elemento seleccionado
                const selectedElement = document.getElementById(selectedModule);
                if (selectedElement instanceof HTMLElement) {
                    selectedElement.classList.add('selectedModuleTabButton');
                }
            };

            updateModuleSelection();
        }
    }, [selectedModule, editorProps, filteredModules]);

    const handleTabClose = (moduleLabel: string) => {
        if (openTabs.length > 1 || selectedModule !== moduleLabel) {
            removeTab(moduleLabel);
        }
    };

    const markModuleAsChanged = (moduleName: string): void => {
        const _changedModules = [...changedModules];
        if (!changedModules.includes(moduleName)) {
            _changedModules.push(moduleName);
            setChangedModules(_changedModules);
        }
    };

    const unmarkModuleAsChanged = (): void => {
        setChangedModules([]);
        setUpdateComponentBackupFlow(!updateComponentBackupFlow);
    };

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        })
    );

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (over && active.id !== over.id) {
            const oldIndex = openTabs.findIndex(tab => tab.label === active.id);
            const newIndex = openTabs.findIndex(tab => tab.label === over.id);

            if (oldIndex !== -1 && newIndex !== -1) {
                setOpenTabs(tabs => arrayMove(tabs, oldIndex, newIndex));
            }
        }
    };

    useEffect(() => {
        if (editorProps?.di) {
            editorProps.di.setSelectionMode('rect');
            editorProps.di.setSelectionShape(selectionShape);
            editorProps.di.setSelectionButton(selectionButton);
        }
    }, [editorProps?.di, selectionShape, selectionButton]);

    const handleToggleSelectionShape = () => {
        const newShape = selectionShape === 'lasso' ? 'marquee' : 'lasso';
        setSelectionShape(newShape);
        if (editorProps?.di) {
            editorProps.di.setSelectionShape(newShape);
        }
    };

    const handleToggleSelectionButton = () => {
        const newButton = selectionButton === 0 ? 1 : 0;
        setSelectionButton(newButton);
        setPreviousSelectionButton(newButton);
        if (editorProps?.di) {
            editorProps.di.setSelectionButton(newButton);
        }
    };

    useEffect(() => {
        if (editorProps?.di) {
            const handleShiftKeyDown = (e: KeyboardEvent) => {
                const modalElement = document.getElementsByClassName('MuiDialog-root')[0];
                if (modalElement) return;

                if (e.shiftKey && !e.repeat) {
                    e.preventDefault();
                    if (selectionButton === previousSelectionButton) {
                        const newButton = selectionButton === 0 ? 1 : 0;
                        setPreviousSelectionButton(selectionButton);
                        setSelectionButton(newButton);
                        editorProps.di.setSelectionButton(newButton);
                    }
                }
            };

            const handleShiftKeyUp = (e: KeyboardEvent) => {
                const modalElement = document.getElementsByClassName('MuiDialog-root')[0];
                if (modalElement) return;

                if (e.key === 'Shift') {
                    e.preventDefault();
                    if (selectionButton !== previousSelectionButton) {
                        setSelectionButton(previousSelectionButton);
                        editorProps.di.setSelectionButton(previousSelectionButton);
                    }
                }
            };

            // Remove existing event listeners before adding new ones
            document.removeEventListener('keydown', handleShiftKeyDown);
            document.removeEventListener('keyup', handleShiftKeyUp);

            // add event listener to the document
            document.addEventListener('keydown', handleShiftKeyDown);
            document.addEventListener('keyup', handleShiftKeyUp);

            return () => {
                document.removeEventListener('keydown', handleShiftKeyDown);
                document.removeEventListener('keyup', handleShiftKeyUp);
            };
        }
    }, [editorProps?.di, previousSelectionButton, selectionButton]);

    const handleEditModule = (oldName: string, newName: string): void => {
        // Update modules
        const modulesCopy = modules.slice();
        const moduleIndex = modulesCopy.findIndex((module: Module) => module.label === oldName);
        if (moduleIndex !== -1) {
            // Save current module content before updating
            const currentContent = modulesCopy[moduleIndex].content;

            // Update module with new name but keep same content
            modulesCopy[moduleIndex] = {
                ...modulesCopy[moduleIndex],
                label: newName,
                content: currentContent.map(node => (
                    {
                        ...node,
                        module: newName, // Update module reference in each node
                    }
                )),
            };
            setModules(modulesCopy);

            // Update open tabs
            const newOpenTabs = openTabs.map(tab =>
                tab.label === oldName ? { ...tab, label: newName } : tab
            );
            setOpenTabs(newOpenTabs);

            // Update selected module if it was the one being edited
            if (selectedModule === oldName) {
                setSelectedModule(newName);
            }

            // Update changed modules if necessary
            if (changedModules.includes(oldName)) {
                setChangedModules(changedModules.map(m => m === oldName ? newName : m));
            }

            // Mark this module as changed
            if (!changedModules.includes(newName)) {
                setChangedModules([...changedModules, newName]);
            }
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" open={open}>
                    <Toolbar sx={{ '& .MuiToolbar-root': { background: '#ffffff00', minHeight: '40px !important' } }}>
                        <Tooltip title={t('Open options')}>
                            <IconButton
                                color="primary"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={[
                                    {
                                        mr: 1,
                                    },
                                    open && { display: 'none' },
                                ]}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>
                        {showAlert &&
                            <Alert severity='success' className='main-alert'>
                                <Typography textAlign={'center'} variant='body1' gutterBottom>
                                    {alertMessage}
                                </Typography>
                            </Alert>
                        }
                        <Stack direction="row" spacing={0.1} alignItems="center" sx={{position: 'absolute', right: '1em'}}>
                            <Box sx={{ flexShrink: 0, ml: 0.75 }}>
                                <Tooltip title={selectionButton === 0 ? t('selection_options.middle_mouse_button') : t('selection_options.left_mouse_button')}>
                                    <IconButton
                                        size='small'
                                        aria-label="toggle-selection-button"
                                        onClick={handleToggleSelectionButton}
                                        color="primary"
                                        sx={{ ml: 1 }}
                                    >
                                        {selectionButton === 0 ? <ControlCamera /> : <AdsClick />}
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Box sx={{ flexShrink: 1, ml: 0.75 }}>
                                <Tooltip title={selectionShape === 'lasso' ? t('selection_options.marquee_selection') : t('selection_options.lasso_selection')}>
                                    <IconButton
                                        size='small'
                                        aria-label="toggle-selection-shape"
                                        onClick={handleToggleSelectionShape}
                                        color="primary"
                                        sx={{ ml: 1 }}
                                    >
                                        {selectionShape === 'lasso' ? <MarqueeSelectIcon /> : <LassoSelectIcon />}
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <AutoArrangeButton di={editorProps?.di} />
                            <SearchNode
                                modules={modules}
                                setSelectedModule={setSelectedModule}
                                di={editorProps?.di}
                            />
                            <RestoreFlow
                                flow_id={flowId}
                                openModule={openModule}
                                generateModules={generateModules}
                                updateComponentBackupFlow={updateComponentBackupFlow}
                                changedModules={changedModules}
                                setChangedModules={setChangedModules}
                                di={editorProps?.di}
                            />
                            <FullScreen />
                            <FlowVariables showMainAlert={showMainAlert} />
                            <ExportButton
                                di={editorProps?.di}
                                saveModule={() => saveModules(selectedModule)}
                                showMainAlert={showMainAlert}
                                unmarkModuleAsChanged={unmarkModuleAsChanged}
                            />
                        </Stack>
                        <Box
                            className='module-list-container'
                            sx={{
                                flexGrow: 1,
                                bgcolor: 'background.paper',
                                position: 'relative',
                            }}
                        >
                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={handleDragEnd}
                                modifiers={[restrictToHorizontalAxis]}
                            >
                                <SortableContext
                                    items={openTabs.map(tab => tab.label)}
                                    strategy={horizontalListSortingStrategy}
                                >
                                    <Tabs
                                        value={selectedTabIndex}
                                        onChange={handleTabChange}
                                        aria-label="module tabs"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        sx={{
                                            minHeight: '32px',
                                            '& .MuiTabs-flexContainer': {
                                                gap: '1px',
                                                minHeight: '32px',
                                            },
                                            [`& .${tabsClasses.scrollButtons}`]: {
                                                '&.Mui-disabled': { opacity: 0.3 },
                                                color: 'primary.main',
                                                height: '30px',
                                            },
                                            '& .MuiTabs-indicator': {
                                                display: 'none',
                                            },
                                            '& .MuiTabs-scroller': {
                                                overflow: 'auto !important',
                                                scrollbarWidth: 'thin',
                                                '&::-webkit-scrollbar': {
                                                    height: '6px',
                                                },
                                                '&::-webkit-scrollbar-track': {
                                                    background: '#f1f1f1',
                                                },
                                                '&::-webkit-scrollbar-thumb': {
                                                    background: '#888',
                                                    borderRadius: '3px',
                                                },
                                            },
                                        }}
                                    >
                                        {openTabs.map((tab, index) => {
                                            const isChanged = changedModules.includes(tab.label);
                                            return (
                                                <SortableTab
                                                    key={tab.label}
                                                    tab={tab}
                                                    handleTabClose={handleTabClose}
                                                    isChanged={isChanged}
                                                    handleTabChange={(e) => handleTabChange(e, index)}
                                                    selectedModule={selectedModule}
                                                    openTabs={openTabs}
                                                />
                                            );
                                        })}
                                    </Tabs>
                                </SortableContext>
                            </DndContext>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <Box sx={{ display: 'flex' }}>
                        <DrawerHeader sx={{ minHeight: '40px !important' }}>
                            <Typography variant='h6' textAlign={'left'} gutterBottom>
                                Menuflow
                            </Typography>
                            <Tooltip title={t('Close options')}>
                                <IconButton onClick={handleDrawerClose} sx={{ ml: '5rem' }} color='info'>
                                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                </IconButton>
                            </Tooltip>
                        </DrawerHeader>
                    </Box>
                    <Divider />
                    <Box sx={{ m: 1, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant='body1'>
                            {t('Modules')}
                        </Typography>
                        <Box sx={{ mr: 2 }}>
                            <Tooltip title={t('new_module')}>
                                <IconButton
                                    size='small'
                                    aria-label="add-module"
                                    className='moduleIconButton'
                                    onClick={() => setOpenNewModal(true)}
                                    sx={{ ml: 1 }}
                                >
                                    <Add fontSize={'small'} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t('remove_module')}>
                                <IconButton
                                    size='small'
                                    aria-label="remove-module"
                                    className='moduleIconButton'
                                    onClick={() => setOpenRemoveModal(true)}
                                    sx={{ ml: 1 }}
                                >
                                    <Remove fontSize={'small'} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box sx={{ m: 1 }}>
                        <TextField
                            id="input-with-icon-textfield"
                            label={t('Search modeles')}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            variant="outlined"
                            fullWidth
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize={'small'} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size='small'
                                            sx={{ height: '24px', width: '24px' }}
                                            aria-label="clear-search"
                                            onClick={() => setSearchTerm('')}
                                        >
                                            <Tooltip title={t('clear')}>
                                                <Clear fontSize={'small'} />
                                            </Tooltip>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <List sx={{ overflow: 'auto', maxHeight: '88vh' }}>
                        {filteredModules.map((module: Module, index: number) => (
                            <ListItem
                                key={`module-${index}`}
                                disablePadding
                                id={module.label}
                                className='moduleTabButton'
                                sx={{
                                    '& .MuiListItemButton-root': {
                                        pr: '40px',
                                    },
                                }}
                                secondaryAction={
                                    <IconButton
                                        size='small'
                                        edge="end"
                                        aria-label="edit"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setModuleToEdit(module.label);
                                            setOpenEditModal(true);
                                        }}
                                    >
                                        <Edit fontSize="small" />
                                    </IconButton>
                                }
                                onClick={() => {
                                    updateSelectedModule(module.label);
                                }}
                            >
                                <Tooltip title={module.label} placement="right">
                                    <ListItemButton>
                                        <ListItemIcon sx={{ minWidth: 36 }}>
                                            <AccountTreeIcon sx={{ fontSize: '1.2rem' }} />
                                        </ListItemIcon>
                                        <Typography
                                            noWrap
                                            component="span"
                                            sx={{
                                                fontSize: '0.875rem',
                                                maxWidth: '140px',
                                                display: 'block',
                                            }}
                                        >
                                            {module.label}
                                        </Typography>
                                    </ListItemButton>
                                </Tooltip>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <Box sx={{ p: 1 }}>
                        <KeyboardShortcuts />
                    </Box>
                </Drawer>
            </Box>
            <Main open={open}>
                <DrawerHeader sx={{ minHeight: '40px !important' }} />
                <div className='container-editor'>
                    <div ref={ref} className="rete"></div>
                </div>
            </Main>
            <NewModuleModal open={openNewModal} onClose={() => setOpenNewModal(false)} save={saveModule} />
            <RemoveModuleModal open={openRemoveModal} models={module} onClose={() => setOpenRemoveModal(false)} remove={removeModule} />
            <EditModuleModal
                open={openEditModal}
                currentName={moduleToEdit}
                onClose={() => setOpenEditModal(false)}
                onSave={handleEditModule}
            />
        </>
    );
};