import { NodeEditor } from 'rete';
import { t } from 'i18next';

import { AreaExtensions, AreaPlugin, Zoom, Drag } from 'rete-area-plugin';
import {
    ConnectionPlugin,
    Presets as ConnectionPresets,
} from 'rete-connection-plugin';
import {
    ReactPlugin,
    Presets as ReactPresets,
} from 'rete-react-plugin';
import { createRoot } from 'react-dom/client';

import {
    ContextMenuPlugin,
    Presets as ContextMenuPresets,
} from 'rete-context-menu-plugin';
import { CustomNodeStart } from './nodes/customization/CustomNodeStart';
import { CustomNode } from './nodes/customization/CustomNode';
import { CustomMenu, CustomCommon, CustomSearch, CustomSubitems, CustomItemWithIcon } from './nodes/customization/CustomContextMenu';
import {
    MessageNode,
    InputNode,
    SwitchNode,
    SetVarsNode,
    SubroutineNode,
    DelayNode,
    LocationNode,
    LeaveNode,
    InviteUserNode,
    CheckTimeNode,
    MediaNode,
    DistributeChatNode,
    StartNode,
    HttpRequestNode,
    EmailNode,
    GptAssistantNode,
    InteractiveNode,
    StartSubNode,
    GotoNode,
    StartSectionNode,
    FormNode,
} from './nodes';
import {
    TextArea,
    TextAreaControl,
    GoSectionButton,
    GoSectionButtonControl,
    Input,
    InputControl,
    InputModal,
    InputModalControl,
    SwitchModal,
    SwitchModalControl,
    MediaModal,
    MediaModalControl,
    CheckTimeModal,
    CheckTimeModalControl,
    InviteUserModal,
    InviteUserModalControl,
    SetVarsModal,
    SetVarsModalControl,
    MessageModalControl,
    MessageModal,
    DistributeChatModalControl,
    DistributeChatModal,
    HttpRequestModalControl,
    HttpRequestModal,
    EmailModal,
    EmailModalControl,
    GptAssistantModal,
    GptAssistantModalControl,
    InteractiveModalControl,
    InteractiveModal,
    SubroutineModal,
    SubroutineModalControl,
    LeaveModal,
    LeaveModalControl,
    LocationModal,
    LocationModalControl,
    DelayModal,
    DelayModalControl,
    StartSubModal,
    StartSubModalControl,
    GotoModalControl,
    GotoModal,
    StartSectionModalControl,
    StartSectionModal,
    FormModalControl,
    FormModal,
} from './controls';
import { Schemes, AreaExtra, DiContainer, SelectionMode } from './types';
import {
    AutoArrangePlugin,
    Presets as ArrangePresets,
    ArrangeAppliers,
} from 'rete-auto-arrange-plugin';
import { deleteNode, copyNode, cutNode, pasteNode, deleteNodeFromLocalStorage } from './shorcut_functions';
import {
    HistoryPlugin,
    Presets as HistoryPresets,
} from 'rete-history-plugin';
import { SelectableConnection } from './components/SelectableConnection';
import { autoArrangeNodes } from './autoArrangeNodes';
import { setupSelection } from './selection';

type createEditorModel = {
    destroy: () => void;
    arranger: () => void;
    di: DiContainer;
};

export async function createEditor(container: HTMLElement): Promise<createEditorModel> {
    const editor = new NodeEditor<Schemes>();
    const area = new AreaPlugin<Schemes, AreaExtra>(container);
    const connection = new ConnectionPlugin<Schemes, AreaExtra>();
    const reactRender = new ReactPlugin<Schemes, AreaExtra>({ createRoot });
    const arrange = new AutoArrangePlugin<Schemes>();
    const history = new HistoryPlugin<Schemes>();
    area.area.setZoomHandler(null);
    history.addPreset(HistoryPresets.classic.setup());

    // Add scroll and zoom handler
    container.addEventListener('wheel', (e: WheelEvent) => {
        e.preventDefault();

        if (e.ctrlKey) return;

        const scrollSpeed = 1;
        if (e.shiftKey) {
            const direction = e.deltaY > 0 ? 1 : -1;
            const offset = 30;
            area.area.translate(area.area.transform.x + direction * offset, area.area.transform.y);
        } else {
            area.area.translate(area.area.transform.x, area.area.transform.y - e.deltaY * scrollSpeed);
        }
    }, { passive: false });

    // Aseguramos que el container pueda recibir el foco
    container.tabIndex = 0;
    container.style.outline = 'none';

    // Recuperar el enfoque cuando el mouse entra en el contenedor
    container.addEventListener('mouseenter', () => {
        container.focus();
    });

    container.addEventListener('keydown', (e: KeyboardEvent) => {
        const modalElement = document.getElementsByClassName('MuiDialog-root')[0];
        if (modalElement) return;

        if (e.ctrlKey) {
            area.area.setZoomHandler(new Zoom(0.1));
        }
    });

    container.addEventListener('keyup', (e: KeyboardEvent) => {
        const modalElement = document.getElementsByClassName('MuiDialog-root')[0];
        if (modalElement) return;

        if (!e.ctrlKey) {
            area.area.setZoomHandler(null);
        }
    });

    // Keyboard shortcuts
    container.addEventListener('keydown', async (e) => {
        const modalElement = document.getElementsByClassName('MuiDialog-root')[0];
        if (modalElement) return;

        if (e.key === 'Delete') deleteNode(selector, editor);
        if (e.ctrlKey && e.key === 'c') copyNode(selector, di);
        if (e.ctrlKey && e.key === 'v') pasteNode(di);
        if (e.ctrlKey && e.key === 'x') cutNode(selector, di);
        if (e.ctrlKey && e.key === 'z') await history.undo();
        if (e.ctrlKey && e.key === 'y') await history.redo();

        // Auto arrange for selected nodes with Ctrl+O or Ctrl+A
        if (e.ctrlKey && (e.key === 'o' || e.key === 'a')) {
            e.preventDefault();
            await autoArrangeNodes(editor, area as AreaPlugin<Schemes, never>);
        }
    });

    const applier = new ArrangeAppliers.TransitionApplier<Schemes, never>({
        duration: 500,
        timingFunction: (t) => t,
        async onTick(): Promise<void> {
            // Removemos el zoom automático
            area.area.setZoomHandler(null);
        },
    });

    arrange.addPreset(ArrangePresets.classic.setup());

    reactRender.addPreset(
        ReactPresets.classic.setup({
            customize: {
                node(context) {
                    if (context.payload.label === 'Start') {
                        return CustomNodeStart;
                    }
                    return CustomNode;
                },
                control(data) {
                    if (data.payload instanceof TextAreaControl) {
                        return TextArea;
                    }
                    if (data.payload instanceof InputControl) {
                        return Input;
                    }
                    if (data.payload instanceof GoSectionButtonControl) {
                        return GoSectionButton;
                    }
                    if (data.payload instanceof InputModalControl) {
                        return InputModal;
                    }
                    if (data.payload instanceof SwitchModalControl) {
                        return SwitchModal;
                    }
                    if (data.payload instanceof MediaModalControl) {
                        return MediaModal;
                    }
                    if (data.payload instanceof CheckTimeModalControl) {
                        return CheckTimeModal;
                    }
                    if (data.payload instanceof InviteUserModalControl) {
                        return InviteUserModal;
                    }
                    if (data.payload instanceof SetVarsModalControl) {
                        return SetVarsModal;
                    }
                    if (data.payload instanceof MessageModalControl) {
                        return MessageModal;
                    }
                    if (data.payload instanceof DistributeChatModalControl) {
                        return DistributeChatModal;
                    }
                    if (data.payload instanceof HttpRequestModalControl) {
                        return HttpRequestModal;
                    }
                    if (data.payload instanceof EmailModalControl) {
                        return EmailModal;
                    }
                    if (data.payload instanceof GptAssistantModalControl) {
                        return GptAssistantModal;
                    }
                    if (data.payload instanceof InteractiveModalControl) {
                        return InteractiveModal;
                    }
                    if (data.payload instanceof SubroutineModalControl) {
                        return SubroutineModal;
                    }
                    if (data.payload instanceof LeaveModalControl) {
                        return LeaveModal;
                    }
                    if (data.payload instanceof LocationModalControl) {
                        return LocationModal;
                    }
                    if (data.payload instanceof DelayModalControl) {
                        return DelayModal;
                    }
                    if (data.payload instanceof StartSubModalControl) {
                        return StartSubModal;
                    }
                    if (data.payload instanceof GotoModalControl) {
                        return GotoModal;
                    }
                    if (data.payload instanceof StartSectionModalControl) {
                        return StartSectionModal;
                    }
                    if (data.payload instanceof FormModalControl) {
                        return FormModal;
                    }
                    return null;
                },
                connection() {
                    return SelectableConnectionBind;
                },
            },
        })
    );

    const SelectableConnectionBind = (props: { data: Schemes['Connection'] }) => {
        const id = props.data.id;
        const label = 'connection';
        return (
            <SelectableConnection
                {...props}
                click={() => {
                    selector.add(
                        {
                            id,
                            label,
                            translate() { },
                            unselect() {
                                props.data.selected = false;
                                area.update('connection', id);
                            },
                        },
                        accumulating.active()
                    );
                    props.data.selected = true;
                    area.update('connection', id);
                }}
            />
        );
    };

    const selector = AreaExtensions.selector();
    const accumulating = AreaExtensions.accumulateOnCtrl();
    const nodeSelector = AreaExtensions.selectableNodes(area, selector, {
        accumulating: AreaExtensions.accumulateOnCtrl(),
    });

    const selection = setupSelection(area, {
        selected(ids) {
            const [first, ...rest] = ids;

            selector.unselectAll();
            if (first) {
                nodeSelector.select(first, false);
            }
            for (const id of rest) {
                nodeSelector.select(id, true);
            }
        },
    });

    AreaExtensions.simpleNodesOrder(area);

    const di: DiContainer = {
        updateNode: (id) => area.update('node', id),
        updateControl: (id) => area.update('control', id),
        arranger: (execute:boolean) => execute ? arrange.layout({ applier }) : null,
        editor,
        area,
        history,
        setSelectionMode: ((mode: any) => selection.setMode(mode)) as (mode: SelectionMode) => void,
        setSelectionShape: selection.setShape,
        setSelectionButton(button: 0 | 1) {
            const panningButton = button ? 0 : 1;

            area.area.setDragHandler(new Drag({
                down: e => {
                    if (e.pointerType === 'mouse' && e.button !== panningButton) return false;
                    e.preventDefault();
                    return true;
                },
                move: () => true,
            }));

            selection.setButton(button);
        },
    };

    /**Context menu for the customization of the nodes must take into account the order,
     * if this changes you must adjust the switch in the customization file,
     * if you add a new node also add the corresponding icon.
    */
    const contextMenu = new ContextMenuPlugin<Schemes>({
        items: ContextMenuPresets.classic.setup([
            [t('nodes.start'), (): StartNode => new StartNode()],
            [t('nodes.Distribute Chat'), (): DistributeChatNode => new DistributeChatNode(di)],
            [t('nodes.Messages'), [
                [t('nodes.Message'), (): MessageNode => new MessageNode(di)],
                [t('nodes.Media'), (): MediaNode => new MediaNode(di)],
                [t('nodes.Location'), (): LocationNode => new LocationNode(di)],
            ]],
            [t('nodes.Entries'), [
                [t('nodes.Input'), (): InputNode => new InputNode(di)],
                [t('nodes.Interactive'), (): InteractiveNode => new InteractiveNode(di)],
                [t('nodes.Form'), (): FormNode => new FormNode(di)],
            ]],
            [t('nodes.Validations'), [
                [t('nodes.Check Time'), (): CheckTimeNode => new CheckTimeNode(di)],
                [t('nodes.Switch'), (): SwitchNode => new SwitchNode(di)],
            ]],
            [t('nodes.Subroutines'), [
                [t('nodes.StartSub'), (): StartSubNode => new StartSubNode(di)],
                [t('nodes.Subroutine'), (): SubroutineNode => new SubroutineNode(di)],
                [t('nodes.StartSection'), (): StartSectionNode => new StartSectionNode(di)],
                [t('nodes.Goto'), (): GotoNode => new GotoNode(di)],
            ]],
            [t('nodes.Advanced'), [
                [t('nodes.HttpRequest'), (): HttpRequestNode => new HttpRequestNode(di)],
                [t('nodes.GptAssistant'), (): GptAssistantNode => new GptAssistantNode(di)],
            ]],
            [t('nodes.Utils'), [
                [t('nodes.Invite User'), (): InviteUserNode => new InviteUserNode(di)],
                [t('nodes.Leave'), (): LeaveNode => new LeaveNode(di)],
                [t('nodes.Email'), (): EmailNode => new EmailNode(di)],
                [t('nodes.Set Vars'), (): SetVarsNode => new SetVarsNode(di)],
                [t('nodes.Delay'), (): DelayNode => new DelayNode(di)],
            ]],
        ]),
    });

    reactRender.addPreset(ReactPresets.contextMenu.setup({
        customize: {
            main: () => CustomMenu,
            item: (props) => {
                return () => <CustomItemWithIcon data={props}/>;
            },
            common: () => CustomCommon,
            search: () => CustomSearch,
            subitems: () => CustomSubitems,
        },
    }));

    editor.use(area);

    editor.addPipe(context => {
        if (context.type === 'noderemoved') {
            deleteNodeFromLocalStorage(context.data);
        }
        return context;
    });

    area.use(reactRender);
    area.use(connection);
    area.use(contextMenu);
    area.use(arrange);
    area.use(history);

    connection.addPreset(ConnectionPresets.classic.setup());
    reactRender.addPreset(ReactPresets.classic.setup());
    reactRender.addPreset(ReactPresets.contextMenu.setup());

    return {
        destroy: () => area.destroy(),
        arranger: () => arrange.layout({ applier }),
        di,
    };
};