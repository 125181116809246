import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ClassicPreset as Classic } from 'rete';
import '../Modal.css';
import {
    Box,
    Typography,
    Button,
    Alert,
    TextField,
    IconButton,
    InputAdornment,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { DiContainer, NodeID } from '../../../types';
import { CheckTimeData, Range } from '../../../models/CheckTime';
import { WarningAmber, Settings, Cancel, AvTimer, CheckCircle } from '@mui/icons-material';
import { RangeSection } from './RangeSection';
import moment from 'moment-timezone';
import config from '../../../../config.json';
import { useTranslation } from 'react-i18next';
import { getNextNodeName, nodeNameAlreadyExists } from '../../../../util';
import { StaticCaseVariables } from '../../../components/StaticCaseVariables';

declare type CheckTimeModalControlOptions = {
    modalInfo?: CheckTimeData;
    nodeId: NodeID;
    di: DiContainer;
    socket: Classic.Socket;
};

export class CheckTimeModalControl extends Classic.Control {
    constructor(public options?: CheckTimeModalControlOptions) {
        super();
    }
}

export const CheckTimeModal = (props: { data: CheckTimeModalControl }): JSX.Element => {
    const { t } = useTranslation();
    const [checkTimeData, setCheckTimeData] = useState<CheckTimeData>({
        name: getNextNodeName('Check Time', 'check_time', props.data.options?.di.editor!),
        timezone: config.default_timezone,
        time_ranges: [{ 'start': '', 'end': '' }],
        days_of_week: [{ 'start': '', 'end': '' }],
        days_of_month: [{ 'start': '', 'end': '' }],
        months: [{ 'start': '', 'end': '' }],
        cases: [
            {'id': 'True', 'o_connection': '', 'variables': []},
            {'id': 'False', 'o_connection': '', 'variables': []},
        ],
    });
    const [formErrorVisible, setFormErrorVisible] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [open, setOpen] = useState(false);

    // Obtener todas las zonas horarias
    const timeZones = moment.tz.names();
    const formattedList = timeZones.map((tz : string) => ({ value: tz, label: tz }));

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    useMemo(() => {
        if (props.data.options?.modalInfo && !open) {
            const node_data_copy = JSON.parse(JSON.stringify(props.data.options!.modalInfo));
            setCheckTimeData(node_data_copy);
        };
    }, [open]);

    const onOpen = () => () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const validateForm = (): string | undefined => {
        if (checkTimeData.timezone === '') {
            return t('check_time.timezone_is_required');
        }else if (
            (checkTimeData.time_ranges ?? []).length === 0 &&
            (checkTimeData.days_of_week ?? []).length === 0 &&
            (checkTimeData.days_of_month ?? []).length === 0 &&
            (checkTimeData.months ?? []).length === 0
        ) {
            return t('check_time.at_least_one_range_is_required');
        } else if (nodeNameAlreadyExists(checkTimeData.name, props.data.options?.nodeId!, props.data.options?.di.editor!)) {
            return t('check_time.name_already_exists');
        }

        return;
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formError = validateForm();
        if (formError) {
            showErrorMessage(formError);
            return;
        }

        props.data.options!.modalInfo = checkTimeData;
        props.data.options?.di.updateNode(props.data.options?.nodeId.reteNodeId);
        onClose();
    };

    const add = (key_name: keyof CheckTimeData): void => {
        const newCheckTimeData = { ...checkTimeData };
        (newCheckTimeData[key_name] as Array<Range>).push({ start: '', end: '' });
        setCheckTimeData(newCheckTimeData);
    };

    const remove = (index: number, key_name: keyof CheckTimeData): void => {
        const newCheckTimeData = { ...checkTimeData };
        (newCheckTimeData[key_name] as Array<Range>).splice(index, 1);
        setCheckTimeData(newCheckTimeData);
    };

    const saveStart = (index: number, key_name: keyof CheckTimeData, value: string): void => {
        const newCheckTimeData = { ...checkTimeData };
        (newCheckTimeData[key_name] as Array<Range>)[index].start = value;
        setCheckTimeData(newCheckTimeData);
    };

    const saveEnd = (index: number, key_name: keyof CheckTimeData, value: string): void => {
        const newCheckTimeData = { ...checkTimeData };
        (newCheckTimeData[key_name] as Array<Range>)[index].end = value;
        setCheckTimeData(newCheckTimeData);
    };

    const addVariable = (caseIndex: number): void => {
        const newCases = [...checkTimeData.cases];
        if (newCases[caseIndex].variables) {
            newCases[caseIndex].variables?.push({ key: '', value: '' });
        }
        setCheckTimeData({ ...checkTimeData, cases: newCases });
    };

    const saveVariable = (index: number, caseIndex: number, key: 'key' | 'value', value: string): void => {
        const newCases = [...checkTimeData.cases];
        if (newCases[caseIndex].variables != undefined) {
            (newCases[caseIndex].variables ?? [])[index][key] = value;
        }
        setCheckTimeData({ ...checkTimeData, cases: newCases });
    };

    const removeVariable = (caseIndex: number, index: number): void => {
        const newCases = [...checkTimeData.cases];
        if (newCases[caseIndex].variables) {
            newCases[caseIndex].variables?.splice(index, 1);
        }
        setCheckTimeData({ ...checkTimeData, cases: newCases });
    };

    return (
        <div>
            <IconButton
                aria-label={t('check_time.node_options')}
                size="small"
                className='open-button'
                onPointerDown={(e): void => e.stopPropagation()}
                onClick={onOpen()}
            >
                <Settings fontSize="medium" />
            </IconButton>

            <Dialog
                open={open}
                onClose={onClose}
                scroll={'paper'}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" textAlign={'center'} variant='h6' gutterBottom>
                    {t('check_time.check_time_node_options')}
                    <span className='close' onClick={onClose}>
                        &times;
                    </span>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Box
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <TextField
                            label={t('check_time.name')}
                            id='node-name'
                            value={checkTimeData.name}
                            required
                            onChange={(e): void => setCheckTimeData({ ...checkTimeData, name: e.target.value })}
                            sx={{ m: 1, width: '98.5%' }}
                            size="small"
                        />
                        <Typography variant="caption" display="block" gutterBottom>
                            {t('check_time.timezone_example_America_Bogota')}
                        </Typography>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label={t('check_time.timezone')}
                            size="small"
                            sx={{ m: 1, width: '58ch' }}
                            defaultValue="America/Bogota"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AvTimer />
                                    </InputAdornment>
                                ),
                            }}
                            value={checkTimeData.timezone}
                            onChange={(e): void => setCheckTimeData({ ...checkTimeData, timezone: e.target.value })}
                        >
                            {formattedList.map((option: {value: string, label: string}) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Typography variant="caption" display="block" gutterBottom>
                            {t('check_time.required_fields_*')}
                        </Typography>
                        <RangeSection
                            title={t('check_time.time_ranges')}
                            data={checkTimeData.time_ranges || []}
                            key_name='time_ranges'
                            add={add}
                            remove={remove}
                            saveStart={saveStart}
                            saveEnd={saveEnd}
                        />
                        <RangeSection
                            title={t('check_time.days_of_Week')}
                            data={checkTimeData.days_of_week || []}
                            key_name='days_of_week'
                            add={add}
                            remove={remove}
                            saveStart={saveStart}
                            saveEnd={saveEnd}
                        />
                        <RangeSection
                            title={t('check_time.days_of_month')}
                            data={checkTimeData.days_of_month || []}
                            key_name='days_of_month'
                            add={add}
                            remove={remove}
                            saveStart={saveStart}
                            saveEnd={saveEnd}
                        />
                        <RangeSection
                            title={t('check_time.months')}
                            data={checkTimeData.months || []}
                            key_name='months'
                            add={add}
                            remove={remove}
                            saveStart={saveStart}
                            saveEnd={saveEnd}
                        />
                        <Typography textAlign='left' variant='subtitle1' sx={{ mb: 2 }}>{t('check_time.cases')}</Typography>
                        {checkTimeData.cases.map((item, index) => (
                            <StaticCaseVariables
                                key={index}
                                index={index}
                                data={item}
                                saveVariable={saveVariable}
                                removeVariable={removeVariable}
                                addVariable={addVariable}
                            />
                        ))}
                        <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            {formErrorVisible &&
                                <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                                    {formErrorMessage}
                                </Alert>
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('check_time.cancel')}</Button>
                    <Button type="submit" variant="contained" startIcon={<CheckCircle />}>{t('accept')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};