
import React, { useState, useEffect, useRef } from 'react';
import { ClassicPreset as Classic } from 'rete';
import '../Modal.css';
import {
    Box,
    TextField,
    Typography,
    Button,
    Alert,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { WarningAmber, Settings, Cancel, CheckCircle } from '@mui/icons-material';
import { DiContainer, ImportNodeData, Module, NodeID } from '../../../types';
import { GotoData } from '../../../models/Goto';
import { useTranslation } from 'react-i18next';
import { slugify_text, mainParams } from '../../../../util/util';
import { getNextNodeName, nodeNameAlreadyExists } from '../../../../util';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { StartSectionModalControl } from '../start_section_modal/StartSectionModal';

const [, botMxid] = mainParams();

declare type GotoModalControlOptions = {
    modalInfo?: GotoData;
    nodeId: NodeID;
    di: DiContainer;
    socket: Classic.Socket;
};

export class GotoModalControl extends Classic.Control {
    constructor(public options?: GotoModalControlOptions) {
        super();
    }
}

export const GotoModal = (props: { data: GotoModalControl }): JSX.Element => {
    const { t } = useTranslation();
    const [gotoData, setGotoData] = useState<GotoData>(props.data.options?.modalInfo || {
        name: getNextNodeName('Goto', 'message', props.data.options?.di.editor!, 'goto'),
        o_connection: '',
    });
    const [formErrorVisible, setFormErrorVisible] = useState(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [inputSelectedModule, setInputSelectedModule] = useState('');
    const [startSecNodes, setStartSecNodes] = useState<JSX.Element[]>([]);
    const [selectedModule] = useLocalStorage(`selected_module-${botMxid}`, 'Main');
    const [modules] = useLocalStorage<Module[]>(botMxid, []);

    useEffect(() => {
        const joinNodes = modules.flatMap((module: Module) => module.content);
        setInputSelectedModule(joinNodes.find((node) => node.id === gotoData.o_connection)?.module || '');
    }, []);

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    useEffect(() => {
        if (props.data.options?.modalInfo && !open) {
            setGotoData(props.data.options.modalInfo);
        }
    }, [gotoData, open]);

    useEffect(() => {
        if(inputSelectedModule === '') return;
        let startSecNodes: JSX.Element[] = [];
        if (inputSelectedModule === selectedModule) {
            const options = props.data.options?.di.editor.getNodes().filter(node => node.label === 'StartSection');
            if (!options) return;

            startSecNodes = options!.map((option, index) => {
                const nodeInfo = (option.controls.modal as StartSectionModalControl)?.options?.modalInfo;
                return (
                    <MenuItem key={`sub-${index}`} value={slugify_text(nodeInfo?.name)}>
                        {nodeInfo?.name}
                    </MenuItem>
                );
            });
        }else{
            const moduleIndex = modules.findIndex((module: Module) => module.label === inputSelectedModule);
            startSecNodes = (modules[moduleIndex].content as ImportNodeData[]).filter((node) => node.subtype === 'start_section').map(
                (node: any, index: number) => (
                    <MenuItem key={`start-${index}`} value={node.id}>
                        {node.name}
                    </MenuItem>
                )
            );
        }
        setStartSecNodes(startSecNodes);
    }, [inputSelectedModule]);

    const onOpen = () => () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const renderModulesOptions = (): JSX.Element[] => {
        return modules.map((option, index) => (
            <MenuItem key={`sect-${index}`} value={option.label}>
                {t(option.label)}
            </MenuItem>
        ));
    };

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const validateForm = (): string | undefined => {
        if (nodeNameAlreadyExists(gotoData.name, props.data.options?.nodeId!, props.data.options?.di.editor!)) {
            return t('subroutine.name_already_exists');
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const error = validateForm();
        if (error) {
            showErrorMessage(error);
            return;
        }

        props.data.options!.modalInfo = gotoData;
        props.data.options!.di.updateNode(props.data.options!.nodeId.reteNodeId);
        onClose();
    };

    return (
        <div>
            <IconButton
                aria-label="Node Options"
                size="small"
                className='open-button'
                onPointerDown={(e): void => e.stopPropagation()}
                onClick={onOpen()}
            >
                <Settings fontSize="medium" />
            </IconButton>

            <Dialog
                open={open}
                onClose={onClose}
                scroll={'paper'}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
                fullWidth={true}
                maxWidth={'sm'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" textAlign={'center'} variant='h6' gutterBottom>
                    {t('goto.goto_node_options')}
                    <span className='close' onClick={onClose}>
                        &times;
                    </span>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Box
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Typography variant="caption" display="block" gutterBottom>
                            {t('goto.required_fields')}
                        </Typography>
                        <TextField
                            label={t('goto.name')}
                            id='node-name'
                            value={gotoData.name}
                            required
                            onChange={(e): void => setGotoData({ ...gotoData, name: e.target.value })}
                            sx={{ m: 1, width: '98.5%' }}
                            size="small"
                        />
                        <FormControl sx={{ m: 1, width: '47%' }} size="small">
                            <InputLabel id='module-label'>{t('goto.modules')}</InputLabel>
                            <Select
                                labelId='module-label'
                                id='modules-select'
                                value={inputSelectedModule}
                                required
                                onChange={(e): void => setInputSelectedModule(e.target.value)}
                                label={t('goto.modules')}
                                autoFocus
                            >
                                {renderModulesOptions()}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, width: '47%' }} size="small">
                            <InputLabel id='start-sec-label'>{t('goto.goto_section')}</InputLabel>
                            <Select
                                labelId='start-section-label'
                                id='start-section-select'
                                value={gotoData.o_connection}
                                required
                                onChange={(e): void => setGotoData({ ...gotoData, o_connection: e.target.value })}
                                label={t('goto.goto_section')}
                                autoFocus
                            >
                                {startSecNodes}
                            </Select>
                        </FormControl>
                        <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            {formErrorVisible &&
                                <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                                    {formErrorMessage}
                                </Alert>
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('goto.cancel')}</Button>
                    <Button type="submit" variant="contained" startIcon={<CheckCircle />}>{t('accept')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};