import { Box, IconButton, Tooltip } from '@mui/material';
import { AutoFixHigh } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { DiContainer } from '../rete/types';

interface AutoArrangeButtonProps {
    di?: DiContainer;
}

export const AutoArrangeButton = ({ di }: AutoArrangeButtonProps) => {
    const { t } = useTranslation();

    const handleAutoArrange = () => {
        if (di?.arranger) {
            di.arranger(true);
        }
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <Tooltip title={t('Auto arrange nodes')}>
                <IconButton
                    color="primary"
                    size="small"
                    onClick={handleAutoArrange}
                    sx={{ ml: 1 }}
                >
                    <AutoFixHigh />
                </IconButton>
            </Tooltip>
        </Box>
    );
};