import { animateToNode, checkJinja2Syntaxis, separateHistory } from '../util';
import {
    MessageNode,
    InputNode,
    InviteUserNode,
    LeaveNode,
    DelayNode,
    CheckTimeNode,
    LocationNode,
    MediaNode,
    SetVarsNode,
    SubroutineNode,
    SwitchNode,
    DistributeChatNode,
    StartNode,
    HttpRequestNode,
    EmailNode,
    GptAssistantNode,
    InteractiveNode,
    StartSubNode,
    StartSectionNode,
    GotoNode,
    FormNode,
} from './nodes';
import { ExportCase, ListItem, Case } from './models/Switch';
import { toRange } from './models/CheckTime';
import { SetVar } from './models/SetVars';
import { ExportHttpRequestNode } from './models/HttpRequest';
import { DiContainer, Node, ImportNodeData, NodeData } from './types';
import { ClassicPreset as Classic } from 'rete';
import { InputControl } from './controls';
import { ExportDistributeChatJsonData } from './models/DistributeChat';

const addNodeConnections = (node: Node, cases: ExportCase[]): void => {
    for (const inputCase of cases) {
        if (
            inputCase.id === 'default' ||
            checkJinja2Syntaxis(inputCase.o_connection) ||
            inputCase.o_connection === 'finish'
        ) {
            continue;
        }

        const caseIdentifier = inputCase.id || inputCase.case || '';
        node.addOutput(
            caseIdentifier, new Classic.Output(new Classic.Socket('socket'), caseIdentifier, false)
        );
    }
};

const serialize_object = (variables: object): ListItem[] => {
    const vars: ListItem[] = [];
    for (const key in variables) {
        if (variables.hasOwnProperty(key)) {
            vars.push({
                key: key,
                value: (variables as Record<string, string>)[key],
            });
        }
    }
    return vars;
};

const checkLoadConnection = (value: string): boolean => {
    // Check if the value of the connection is a jinja2 variable or the string 'finish'
    // It is used to determine if the connection should be processed and saved
    return checkJinja2Syntaxis(value) || value === 'finish';
};

const serializeCases = (cases: ExportCase[]): Case[] => {
    return cases.map((c: ExportCase) => {
        return {
            id: c.id || c.case || '',
            o_connection: checkLoadConnection(c.o_connection) ? c.o_connection : '',
            variables: serialize_object(c.variables || []),
        };
    });
};

const newNode = (type: string, node: ImportNodeData, di: DiContainer): Node | null => {
    let newNode: Node | null = null;
    if (type === 'message' && node.id === 'start') {
        newNode = new StartNode();
    } else if (type === 'message' && node.subtype === 'start_sub') {
        const data = {
            node_id: node.id,
            startsub_data: {
                name: node.name,
            },
        };
        newNode = new StartSubNode(di, data);
    } else if (type === 'message' && node.subtype === 'goto') {
        const data = {
            node_id: node.id,
            goto_data: {
                name: node.name,
                o_connection: node.o_connection,
            },
        };
        newNode = new GotoNode(di, data);
    } else if (type === 'message' && node.subtype === 'start_section') {
        const data = {
            node_id: node.id,
            start_section_data: {
                name: node.name,
            },
        };
        newNode = new StartSectionNode(di, data);
    } else if (type === 'message') {
        const data = {
            node_id: node.id,
            message_type: node.message_type,
            nodeData: { name: node.name, text: node.text, message_type: node.message_type },
        };
        newNode = new MessageNode(di, data);
    } else if (type === 'input') {
        const node_height = 180 + (node.cases.length * 30);
        const data = {
            node_id: node.id,
            height: node_height,
            input_data: {
                name: node.name,
                text: node.text,
                variable: node.variable,
                validation: node.validation || '',
                input_type: node.input_type,
                validation_fail: {
                    message: node.validation_fail?.message,
                    attempts: node.validation_fail?.attempts ? node.validation_fail.attempts.toString() : '',
                },
                middlewares: node.middlewares || [],
                inactivity_options: {
                    chat_timeout: node.inactivity_options?.chat_timeout,
                    warning_message: node.inactivity_options?.warning_message,
                    time_between_attempts: node.inactivity_options?.time_between_attempts,
                    attempts: node.inactivity_options?.attempts,
                },
                cases: serializeCases(node.cases),
            },
        };

        newNode = new InputNode(di, data);
        addNodeConnections(newNode, node.cases);
    } else if (type === 'invite_user') {
        const data = {
            node_id: node.id,
            input_data: {
                name: node.name,
                timeout: node.timeout.toString(),
                invitee: node.invitee,
                cases: serializeCases(node.cases),
            },
        };
        newNode = new InviteUserNode(di, data);
    } else if (type === 'leave') {
        const data = {
            node_id: node.id,
            leave_data: {
                name: node.name,
                reason: node.reason,
            },
        };
        newNode = new LeaveNode(di, data);
    } else if (type === 'delay') {
        const data = {
            node_id: node.id,
            delay_data: {
                name: node.name,
                time: node.time,
            },
        };
        newNode = new DelayNode(di, data);
    } else if (type === 'check_time') {
        const data = {
            node_id: node.id,
            check_time_data: {
                name: node.name,
                timezone: node.timezone,
                time_ranges: node.time_ranges.map((time: string) => toRange(time)) || [],
                days_of_week: node.days_of_week.map((day: string) => toRange(day)) || [],
                days_of_month: node.days_of_month.map((day: string) => toRange(day)) || [],
                months: node.months.map((month: string) => toRange(month)) || [],
                cases: serializeCases(node.cases),
            },
        };
        newNode = new CheckTimeNode(di, data);
    } else if (type === 'location') {
        const data = {
            node_id: node.id,
            location_data: {
                name: node.name,
                latitude: node.latitude,
                longitude: node.longitude,
            },
        };

        newNode = new LocationNode(di, data);
    } else if (type === 'media') {
        const data = {
            node_id: node.id,
            media_node_data: {
                name: node.name,
                message_type: node.message_type,
                text: node.text,
                url: node.url,
                info: {
                    mimetype: node.info?.mimetype,
                    size: node.info?.size?.toString(),
                    height: node.info?.height?.toString(),
                    width: node.info?.width?.toString(),
                },
            },
        };

        newNode = new MediaNode(di, data);
    } else if (type === 'set_vars') {
        const set: SetVar[] = [];
        for (const key in node.variables?.set) {
            if (node.variables?.set.hasOwnProperty(key)) {
                set.push({
                    name: key,
                    value: (node.variables?.set as Record<string, string>)[key],
                });
            }
        }
        const data = {
            node_id: node.id,
            set_vars_data: {
                name: node.name,
                set: set || [],
                unset: node.variables?.unset || [],
            },
        };
        newNode = new SetVarsNode(di, data);
    } else if (type === 'subroutine') {
        const data = {
            node_id: node.id,
            subroutine_data: {
                name: node.name,
                go_sub: node.go_sub,
            },
        };
        newNode = new SubroutineNode(di, data);
    } else if (type === 'switch') {
        const node_height = 180 + (node.cases.length * 30);
        const data = {
            node_id: node.id,
            height: node_height,
            switch_node_data: {
                name: node.name,
                validation: node.validation || '',
                validation_fail: {
                    message: node.validation_fail?.message,
                    attempts: node.validation_fail?.attempts ? node.validation_fail.attempts.toString() : '',
                },
                cases: serializeCases(node.cases),
            },
        };
        newNode = new SwitchNode(di, data);
        addNodeConnections(newNode, node.cases);
    } else if (type === 'http_request' && node.subtype === 'distribute_chat') {
        const data = {
            name: (node as ExportHttpRequestNode).name,
            destination: (node.json as ExportDistributeChatJsonData).destination,
            joined_message: (node.json as ExportDistributeChatJsonData).joined_message,
            put_enqueued_portal: (node.json as ExportDistributeChatJsonData).put_enqueued_portal,
            force_distribution: (node.json as ExportDistributeChatJsonData).force_distribution,
            queue_timeout: Number((node.json as ExportDistributeChatJsonData).queue_timeout),
            campaign: (node.json as ExportDistributeChatJsonData).campaign,
            subcampaign: (node.json as ExportDistributeChatJsonData).subcampaign,
            cases: serializeCases((node as ExportHttpRequestNode).cases).filter((c: Case) => c.id !== '200'),
            priority: (node.json as ExportDistributeChatJsonData).priority,
        };
        newNode = new DistributeChatNode(di, { node_id: node.id, nodeData: data });
    } else if (type === 'http_request') {
        const node_height = 180 + (node.cases.length * 30);
        const data = {
            node_id: node.id,
            height: node_height,
            nodeData: {
                name: node.name,
                middleware: node.middleware,
                method: node.method,
                url: node.url,
                json: JSON.stringify(node.json, null, 2),
                query_params: serialize_object(node.query_params || {}),
                headers: serialize_object(node.headers || {}),
                variables: serialize_object(node.variables || {}),
                cases: serializeCases(node.cases),
            },
            rawData: node,
        };
        newNode = new HttpRequestNode(di, data);
        addNodeConnections(newNode, node.cases);
    } else if (type === 'email') {
        const data = {
            node_id: node.id,
            nodeData: {
                name: node.name,
                type: node.type,
                server_id: node.server_id,
                subject: node.subject,
                recipients: node.recipients,
                attachments: node.attachments || [],
                text: node.text,
                format: node.format,
                encode_type: node.encode_type,
            },
        };
        newNode = new EmailNode(di, data);
    } else if (type === 'gpt_assistant') {
        const node_height = 180 + (node.cases.length * 30);
        const data = {
            node_id: node.id,
            height: node_height,
            assistant_data: {
                name: node.name,
                assistant_id: node.assistant_id,
                api_key: node.api_key,
                instructions: node.instructions,
                initial_info: node.initial_info,
                variable: node.variable,
                validation: node.validation || '',
                validation_fail: {
                    message: node.validation_fail?.message,
                    attempts: node.validation_fail?.attempts ? node.validation_fail.attempts.toString() : '',
                },
                inactivity_options: {
                    chat_timeout: node.inactivity_options?.chat_timeout,
                    warning_message: node.inactivity_options?.warning_message,
                    time_between_attempts: node.inactivity_options?.time_between_attempts,
                    attempts: node.inactivity_options?.attempts,
                },
                cases: serializeCases(node.cases),
            },
        };

        newNode = new GptAssistantNode(di, data);
        addNodeConnections(newNode, node.cases);
    } else if (type === 'interactive_input') {
        const node_height = 180 + (node.cases.length * 30);
        const data = {
            node_id: node.id,
            height: node_height,
            interactive_data: {
                name: node.name,
                variable: node.variable,
                validation: node.validation || '',
                validation_fail: {
                    message: node.validation_fail?.message,
                    attempts: node.validation_fail?.attempts ? node.validation_fail.attempts.toString() : '',
                },
                inactivity_options: {
                    chat_timeout: node.inactivity_options?.chat_timeout,
                    warning_message: node.inactivity_options?.warning_message,
                    time_between_attempts: node.inactivity_options?.time_between_attempts,
                    attempts: node.inactivity_options?.attempts,
                },
                interactive_message: node.interactive_message,
                cases: serializeCases(node.cases),
            },
        };

        newNode = new InteractiveNode(di, data);
        addNodeConnections(newNode, node.cases);
    } else if (type === 'form') {
        const node_height = 180 + (node.cases.length * 30);
        const data = {
            node_id: node.id,
            height: node_height,
            form_data: {
                name: node.name,
                variable: node.variable,
                validation: node.validation || '',
                validation_fail: {
                    message: node.validation_fail?.message,
                    attempts: node.validation_fail?.attempts ? node.validation_fail.attempts.toString() : '',
                },
                inactivity_options: {
                    chat_timeout: node.inactivity_options?.chat_timeout,
                    warning_message: node.inactivity_options?.warning_message,
                    time_between_attempts: node.inactivity_options?.time_between_attempts,
                    attempts: node.inactivity_options?.attempts,
                },
                template_name: node.template_name,
                language: node.language,
                body_variables: node.body_variables,
                header_variables: node.header_variables,
                button_variables: node.button_variables,
                cases: serializeCases(node.cases),
            },
        };

        newNode = new FormNode(di, data);
        addNodeConnections(newNode, node.cases);
    }
    return newNode;
};

const getConnectionSourceAndTarget = (node_id: string, o_connection: string, nodes_displayed: Node[]): [Node | undefined, Node | undefined] => {
    const source_node = nodes_displayed.find(
        n => (n.controls!.node_id as InputControl)?.options?.value === node_id
    );
    const target_node = nodes_displayed.find(
        n => (n.controls!.node_id as InputControl)?.options?.value === o_connection
    );

    return [source_node, target_node];
};

/**
 * Load a flow into the editor
 * @param di - The di container
 * @param content - The content to load
 * @param preserveView - Whether to preserve the view
 * @param savedNodePositions - The saved node positions
 * @param isNewFlow - Whether the flow is new
 */
export const loadFlow = async (
    di: DiContainer,
    content: NodeData[],
    preserveView: boolean = false,
    savedNodePositions?: Array<{ id: string, position: { x: number, y: number } }>,
    isNewFlow: boolean = true,
    isNodesCopied: boolean = false
): Promise<void> => {
    // if we don't want to preserve the view, we reset to the initial position
    if (!preserveView) {
        const totalWidth = di?.area.container.scrollWidth ?? 0;
        const totalHeight = di?.area.container.scrollHeight ?? 0;
        const centerX = totalWidth / 6;
        const centerY = totalHeight / 20;
        const startX = 0;
        const startY = 0;
        const zoom = 1;

        animateToNode(di?.area!, startX, startY, centerX, centerY, zoom, 1);
    }

    for (const node of content as ImportNodeData[]) {
        const type = node.type;
        const newNodeInstance = newNode(type, node, di);
        if (newNodeInstance) {
            await di.editor.addNode(newNodeInstance);
            // Use saved position if it exists, otherwise use the original node position
            const savedPosition = savedNodePositions?.find(pos => pos.id === newNodeInstance.id)?.position;
            if (savedPosition) {
                di.area.nodeViews.get(newNodeInstance.id)?.translate(savedPosition.x, savedPosition.y);
            } else if (node.x && node.y) {
                di.area.nodeViews.get(newNodeInstance.id)?.translate(node.x, node.y);
            }
            if (isNodesCopied) {
                di.area.nodeViews.get(newNodeInstance.id)?.translate(100, 100);
                const totalWidth = di?.area.container.scrollWidth ?? 0;
                const totalHeight = di?.area.container.scrollHeight ?? 0;
                const centerX = totalWidth / 6;
                const centerY = totalHeight / 20;
                const startX = 0;
                const startY = 0;
                const zoom = 1;
                animateToNode(di?.area!, startX, startY, centerX, centerY, zoom, 1);
            }
        }
    }

    const nodes_displayed = di.editor.getNodes();
    for (const node of content as ImportNodeData[]) {
        if (!node.o_connection && !node.cases) continue;

        if (node.o_connection) {
            const [source_node, target_node] = getConnectionSourceAndTarget(node.id, node.o_connection, nodes_displayed);
            if (!source_node || !target_node) continue;

            try {
                await di.editor.addConnection(new Classic.Connection(source_node!, 'o_connection', target_node!, 'input'));
            } catch (e) {
                console.debug(`Error adding connection from ${source_node!.id} to ${target_node!.id}`);
            }
        } else if (node.cases) {
            for (const nodeCase of node.cases) {
                if (checkJinja2Syntaxis(nodeCase.o_connection) || nodeCase.o_connection === 'finish') {
                    continue;
                }
                const [source_node, target_node] = getConnectionSourceAndTarget(node.id, nodeCase.o_connection, nodes_displayed);
                if (!source_node || !target_node) {
                    continue;
                }

                let nodeCaseId = nodeCase.id || nodeCase.case || '';
                if (nodeCaseId === '202' && node.type === 'http_request' && node.subtype === 'distribute_chat') nodeCaseId = 'enqueued';
                await di.editor.addConnection(new Classic.Connection(source_node!, nodeCaseId, target_node!, 'input'));
            }
        }
    }

    // Only clean the history if it is a new flow
    if (isNewFlow && di.history) {
        separateHistory(di);
    }

    // Force focus on the editor area to enable zoom with Ctrl + scroll
    di.area.container.focus();
};