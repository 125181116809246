import { Add, ExpandLess, ExpandMore } from '@mui/icons-material';
import { InteractiveAction, InteractiveItems, InteractiveOption } from '../../models/Interactive';
import {
    Typography,
    IconButton,
    Grid,
    Box,
    Tooltip,
} from '@mui/material';
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import { t } from 'i18next';
import { reorder } from '../utils/reorderOnDragList';
import InteractiveButtons from './InteractiveButtons';
import InteractiveLists from './InteractiveLists';
import { useState } from 'react';

const InteractiveOptions = (
    props: {
        interactiveTypeOption: string,
        interactiveOptionsValues: InteractiveOption[] | InteractiveItems[] | InteractiveAction[],
        setInteractiveOptionsValues: (interactiveOptionsValues: InteractiveOption[] | InteractiveItems[] | InteractiveAction[]) => void,
    }
) => {

    const [expanded, setExpanded] = useState<boolean>(true);

    const handleExpandClick = () => {
        setExpanded((prev) => !prev);
    };

    const addInteractiveOption = (): void => {
        const newInteractiveOptionsValues = [...props.interactiveOptionsValues];
        if (newInteractiveOptionsValues.length < 3) {
            newInteractiveOptionsValues.push({
                type: 'text',
                title: '',
            });
            props.setInteractiveOptionsValues(newInteractiveOptionsValues as InteractiveOption[]);
        }
    };

    const onDragEnd = (result: DropResult): void => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const interactiveOptionsValues:
            InteractiveOption[] |
            InteractiveItems[] |
            InteractiveAction[] = reorder(
                props.interactiveOptionsValues,
                result.source.index,
                result.destination.index
            );

        props.setInteractiveOptionsValues([
            ...interactiveOptionsValues,
        ] as InteractiveOption[] |
            InteractiveItems[] |
            InteractiveAction[]
        );
    };

    const addInteractiveList = (): void => {
        const newInteractiveOptionsValues = [...props.interactiveOptionsValues];
        if (newInteractiveOptionsValues.length < 3) {
            newInteractiveOptionsValues.push({
                title: '',
                subtitle: '',
                options: [{
                    type: '',
                    title: '',
                    description: '',
                    postback_text: '',
                }],
            });
            props.setInteractiveOptionsValues(newInteractiveOptionsValues as InteractiveItems[]);
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                {/*Check if the interactive message is a button or a list message*/}
                {props.interactiveTypeOption === 'button' && (
                    <>
                        <Grid item xs={10}>
                            <Typography
                                textAlign='left'
                                variant='h6'
                                sx={{ ml: 1, mt: 1, mb: 2, fontSize: '1.2rem' }}
                            >
                                {t('interactive.buttons')}
                            </Typography>
                        </Grid>
                        {/*Button to add a new button message*/}
                        <Grid item xs={2}>
                            <Box className='add-interactive-option' sx={{ ml: 2.7, mt: 1.5 }}>
                                <Tooltip title={t('interactive.tooltip_add_button')}>
                                    <IconButton
                                        aria-label='add-interactive-option'
                                        onClick={addInteractiveOption}
                                        size="small"
                                    >
                                        <Add sx={{ fontSize: '1rem' }} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Grid>
                        {/*
                            Drag and Drop component that allow the user to move the position
                            of the butttons
                        */}
                        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                            <Droppable droppableId="droppableButtons">
                                {(provided: DroppableProvided) => (
                                    //Buttons container
                                    <Grid
                                        container
                                        spacing={2}
                                        className='interactiveOptions'
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {
                                            props.interactiveOptionsValues.map((
                                                item: InteractiveOption | InteractiveItems | InteractiveAction,
                                                index: number,
                                            ) =>
                                                <InteractiveButtons
                                                    index={index}
                                                    item={item}
                                                    interactiveOptionsValues={props.interactiveOptionsValues}
                                                    setInteractiveOptionsValues={props.setInteractiveOptionsValues}
                                                    key={`interactive-lists-component-${index}`}
                                                />
                                            )
                                        }
                                        {provided.placeholder}
                                    </Grid>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                )}
                {props.interactiveTypeOption === 'list' && (
                    <>
                        <Grid container spacing={2} sx={{ ml: 1, mt: 1 }}>
                            <Grid item xs={10}>
                                <Typography
                                    textAlign='left'
                                    variant='h6'
                                >
                                    {t('interactive.items')}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                {/*
                                    Button to add a new section, this sections are used to
                                    separed the different list options
                                */}
                                <Box className='add-interactive-option' sx={{ textAlign: 'end' }}>
                                    <Tooltip title={t('interactive.tooltip_add_section')}>
                                        <IconButton
                                            aria-label='add-interactive-item'
                                            onClick={addInteractiveList}
                                            sx={{ ml: 2 }}
                                        >
                                            <Add sx={{ fontSize: '1rem' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Grid>
                            {/*Button to expand the list sections*/}
                            <Grid item xs={1}>
                                <Box className='expand-interactive-option'>
                                    <IconButton
                                        aria-label='expand-interactive-item'
                                        onClick={() => { handleExpandClick(); }}
                                    >
                                        {!expanded && <ExpandMore sx={{ fontSize: '1rem' }} />}
                                        {expanded && <ExpandLess sx={{ fontSize: '1rem' }} />}
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                        {/*
                            Drag and Drop component that allow the user to move the position
                            of the sections
                        */}
                        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                            <Droppable droppableId="droppableSections">
                                {(provided: DroppableProvided) => (
                                    // Section container that contains the list options
                                    <Grid
                                        container
                                        spacing={2}
                                        className='interactiveItems'
                                        sx={{ ml: 1.9 }}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {
                                            props.interactiveOptionsValues.map((
                                                item: InteractiveOption | InteractiveItems | InteractiveAction,
                                                index: number
                                            ) =>
                                                <InteractiveLists
                                                    index_item={index}
                                                    item={item}
                                                    interactiveOptionsValues={props.interactiveOptionsValues}
                                                    setInteractiveOptionsValues={props.setInteractiveOptionsValues}
                                                    expanded={expanded}
                                                    setExpanded={setExpanded}
                                                    handleExpandClick={handleExpandClick}
                                                    key={`interactive-option-component-${index}`}
                                                />
                                            )
                                        }
                                        {provided.placeholder}

                                    </Grid>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                )}
            </Grid>
        </>
    );
};

export default InteractiveOptions;