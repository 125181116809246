import { NodeEditor } from 'rete';
import { AreaPlugin } from 'rete-area-plugin';
import { Schemes, AreaExtra } from './types';

interface NodeInfo {
    id: string;
    width: number;
    height: number;
    position: [number, number];
    connections: {
        inputs: string[];
        outputs: string[];
    };
    group?: string;
}

export async function autoArrangeNodes(
    editor: NodeEditor<Schemes>,
    area: AreaPlugin<Schemes, AreaExtra>,
) {
    const ANIMATION_DURATION = 800;
    const EASING = (t: number) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    const MIN_NODE_SPACING = 80;
    const VERTICAL_SPACING = 50;
    const LEVEL_SPACING_MULTIPLIER = 1.2;
    const GROUP_PADDING = 20;

    class LayoutManager {
        private cycleDetection = new Set<string>();

        private getNodeInfo(node: any): NodeInfo {
            const view = area.nodeViews.get(node.id);
            if (!view) throw new Error('Node view not found');

            const dims = view.element.getBoundingClientRect();
            const zoom = area.area.transform.k;

            return {
                id: node.id,
                width: dims.width / zoom,
                height: dims.height / zoom,
                position: [view.position.x, view.position.y],
                connections: {
                    inputs: [],
                    outputs: [],
                },
                group: node.group,
            };
        }

        private getNodePosition(nodeId: string): [number, number] {
            const view = area.nodeViews.get(nodeId);
            if (!view) return [0, 0];
            return [view.position.x, view.position.y];
        }

        private analyzeConnections(nodes: NodeInfo[], connections: any[]) {
            const nodeMap = new Map(nodes.map(n => [n.id, n]));

            connections.forEach(conn => {
                const source = nodeMap.get(conn.source);
                const target = nodeMap.get(conn.target);
                if (source) source.connections.outputs.push(conn.target);
                if (target) target.connections.inputs.push(conn.source);
            });
        }

        private calculateLevels(nodes: NodeInfo[]): Map<string, number> {
            const levels = new Map<string, number>();
            const visited = new Set<string>();
            this.cycleDetection.clear();

            const calculateNodeLevel = (node: NodeInfo): number => {
                if (this.cycleDetection.has(node.id)) {
                    console.warn(`Ciclo detectado en el nodo ${node.id}`);
                    return levels.get(node.id) || 0;
                }

                if (visited.has(node.id)) return levels.get(node.id) || 0;

                this.cycleDetection.add(node.id);
                visited.add(node.id);

                const inputLevels = node.connections.inputs
                    .map(id => nodes.find(n => n.id === id))
                    .filter((n): n is NodeInfo => n !== undefined)
                    .map(n => calculateNodeLevel(n));

                const level = inputLevels.length > 0 ? Math.max(...inputLevels) + 1 : 0;
                levels.set(node.id, level);

                this.cycleDetection.delete(node.id);
                return level;
            };

            nodes.forEach(node => {
                if (!visited.has(node.id)) {
                    calculateNodeLevel(node);
                }
            });

            return levels;
        }

        private optimizeVerticalSpacing(levelNodes: NodeInfo[], startY: number = 0): number {
            let currentY = startY;
            const minSpacing = VERTICAL_SPACING / 2;

            levelNodes.forEach((node, index) => {
                if (index > 0) {
                    const prevNode = levelNodes[index - 1];
                    const prevBottom = prevNode.position[1] + prevNode.height;
                    const minY = prevBottom + minSpacing;
                    currentY = Math.max(currentY, minY);
                }
                node.position = [node.position[0], currentY];
                currentY += node.height + VERTICAL_SPACING;
            });

            return currentY;
        }

        private arrangeNodes(nodes: NodeInfo[]) {
            const firstNodeInitialPosition: [number, number] = [...nodes[0].position];

            this.analyzeConnections(nodes, editor.getConnections());
            const levels = this.calculateLevels(nodes);
            const nodesByLevel = new Map<number, NodeInfo[]>();

            nodes.forEach(node => {
                const level = levels.get(node.id) || 0;
                if (!nodesByLevel.has(level)) {
                    nodesByLevel.set(level, []);
                }
                nodesByLevel.get(level)!.push(node);
            });

            const sortedLevels = Array.from(nodesByLevel.entries()).sort(([a], [b]) => a - b);

            let currentX = 0;
            let maxHeight = 0;

            sortedLevels.forEach(([, levelNodes], levelIndex) => {
                levelNodes.sort((a, b) => {
                    const aConnections = a.connections.inputs.length + a.connections.outputs.length;
                    const bConnections = b.connections.inputs.length + b.connections.outputs.length;
                    return bConnections - aConnections;
                });

                levelNodes.forEach(node => {
                    node.position = [currentX, 0];
                });

                const levelHeight = this.optimizeVerticalSpacing(levelNodes);
                maxHeight = Math.max(maxHeight, levelHeight);

                const maxWidth = Math.max(...levelNodes.map(n => n.width));
                const hasNextLevel = levelIndex < sortedLevels.length - 1;
                const spacing = hasNextLevel ?
                    MIN_NODE_SPACING * LEVEL_SPACING_MULTIPLIER :
                    MIN_NODE_SPACING;

                currentX += maxWidth + spacing;
            });

            let minY = Infinity;
            sortedLevels.forEach(([, levelNodes]) => {
                levelNodes.forEach(node => {
                    minY = Math.min(minY, node.position[1]);
                });
            });

            sortedLevels.forEach(([, levelNodes]) => {
                const levelHeight = levelNodes.reduce((sum, node) =>
                    Math.max(sum, node.position[1] + node.height), 0);
                const offset = (maxHeight - levelHeight) / 2;

                levelNodes.forEach(node => {
                    node.position = [node.position[0], node.position[1] + offset];
                });
            });

            const xOffset = firstNodeInitialPosition[0];
            const yOffset = firstNodeInitialPosition[1] - minY;

            nodes.forEach(node => {
                node.position = [
                    node.position[0] + xOffset,
                    node.position[1] + yOffset,
                ];
            });

            return nodes;
        }

        private async animateNodes(nodes: NodeInfo[]) {
            const startTime = Date.now();
            const initialPositions = new Map(
                nodes.map(node => [node.id, this.getNodePosition(node.id)])
            );

            return new Promise<void>(resolve => {
                const animate = () => {
                    const elapsed = Date.now() - startTime;
                    const rawProgress = Math.min(elapsed / ANIMATION_DURATION, 1);
                    const progress = EASING(rawProgress);

                    nodes.forEach(node => {
                        const view = area.nodeViews.get(node.id);
                        if (!view) return;

                        const startPos = initialPositions.get(node.id) || [0, 0];
                        const x = startPos[0] + (node.position[0] - startPos[0]) * progress;
                        const y = startPos[1] + (node.position[1] - startPos[1]) * progress;
                        view.translate(x, y);
                    });

                    if (rawProgress < 1) {
                        requestAnimationFrame(animate);
                    } else {
                        resolve();
                    }
                };

                requestAnimationFrame(animate);
            });
        }

        private arrangeNodesByGroup(nodes: NodeInfo[]) {
            const groups = new Map<string | undefined, NodeInfo[]>();

            nodes.forEach(node => {
                const groupKey = node.group;
                if (!groups.has(groupKey)) {
                    groups.set(groupKey, []);
                }
                groups.get(groupKey)!.push(node);
            });

            groups.forEach((groupNodes, groupKey) => {
                if (groupKey === undefined) return;

                const arranged = this.arrangeNodes(groupNodes);

                let minX = Infinity, minY = Infinity;
                let maxX = -Infinity, maxY = -Infinity;

                arranged.forEach(node => {
                    minX = Math.min(minX, node.position[0]);
                    minY = Math.min(minY, node.position[1]);
                    maxX = Math.max(maxX, node.position[0] + node.width);
                    maxY = Math.max(maxY, node.position[1] + node.height);
                });

                arranged.forEach(node => {
                    node.position = [
                        node.position[0] + GROUP_PADDING,
                        node.position[1] + GROUP_PADDING,
                    ];
                });
            });

            return nodes;
        }

        public async arrange() {
            const selectedNodes = editor.getNodes()
                .filter(node => (node as any).selected)
                .map(node => this.getNodeInfo(node));

            if (selectedNodes.length === 0) return;

            const arrangedNodes = this.arrangeNodesByGroup(selectedNodes);

            const finalArrangedNodes = this.arrangeNodes(arrangedNodes);

            await this.animateNodes(finalArrangedNodes);
        }
    }

    const layoutManager = new LayoutManager();
    await layoutManager.arrange();
}