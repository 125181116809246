
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ClassicPreset as Classic } from 'rete';
import '../Modal.css';
import {
    Box,
    TextField,
    Typography,
    Grid,
    IconButton,
    Button,
    Alert,
    Switch,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    Tooltip,
} from '@mui/material';
import { Add, WarningAmber, Settings, Cancel, Visibility, VisibilityOff, ExpandMore, ExpandLess, CheckCircle } from '@mui/icons-material';
import { DiContainer, NodeID } from '../../../types';
import { GptAssistantData } from '../../../models/GptAssistant';
import { checkJinja2Syntaxis, getNextNodeName, nodeNameAlreadyExists } from '../../../../util';
import { CaseComponent } from '../switch_modal/CaseComponent';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import { Case } from '../../../models/Switch';
import { reorder } from '../../../components/utils/reorderOnDragList';
import JinjaCodeMirrorEditor from '../../../components/JinjaEditor';

declare type GptAssistantModalControlOptions = {
    modalInfo?: GptAssistantData;
    nodeId: NodeID;
    di: DiContainer;
    socket: Classic.Socket;
};

export class GptAssistantModalControl extends Classic.Control {
    constructor(public options?: GptAssistantModalControlOptions) {
        super();
    }
}

export const GptAssistantModal = (props: { data: GptAssistantModalControl }): JSX.Element => {
    const [gptAssistantData, setGptAssistantData] = useState<GptAssistantData>({
        name: getNextNodeName('GptAssistant', 'gpt_assistant', props.data.options?.di.editor!),
        assistant_id: '',
        api_key: '',
        instructions: '',
        initial_info: '',
        variable: '',
        validation: '',
        validation_fail: {
            message: '',
            attempts: '',
        },
        inactivity_options: {
            chat_timeout: '',
            warning_message: '',
            time_between_attempts: '',
            attempts: '',
        },
        cases: [
            { id: '', o_connection: '', variables: [] },
        ],
    });

    const [formErrorVisible, setFormErrorVisible] = useState(false);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [formErrorMessage, setFormErrorMessage] = useState('');
    const [enableInactivityOptions, setEnableInactivityOptions] = useState(false);
    const [enableValidationFail, setEnableValidationFail] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const inactivityOptionsState: boolean = (
            gptAssistantData.inactivity_options?.chat_timeout !== '' &&
            gptAssistantData.inactivity_options?.chat_timeout !== null &&
            gptAssistantData.inactivity_options?.warning_message !== undefined
        );
        setEnableInactivityOptions(inactivityOptionsState);
    }, []);

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    useEffect(() => {
        if (enableInactivityOptions) {
            addNewCase('timeout');
        } else {
            const case_index = gptAssistantData.cases.findIndex((item) => item.id === 'timeout');
            if (case_index >= 0) removeCase(case_index);
        }
    }, [enableInactivityOptions]);

    useEffect(() => {
        if (gptAssistantData.validation_fail?.attempts != '') {
            addNewCase('attempt_exceeded');
        } else {
            const case_index = gptAssistantData.cases.findIndex((item) => item.id === 'attempt_exceeded');
            if (case_index >= 0) removeCase(case_index);
        }
    }, [enableValidationFail]);

    useMemo(() => {
        if (props.data.options?.modalInfo && !open) {
            const node_data_copy = JSON.parse(JSON.stringify(props.data.options.modalInfo));
            setGptAssistantData(node_data_copy);
        }
    }, [open]);

    const onOpen = () => () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const showErrorMessage = (message: string): void => {
        setFormErrorVisible(true);
        setFormErrorMessage(message);
        setTimeout(() => setFormErrorVisible(false), 5000);
    };

    const checkAddConnection = (value: string): boolean => {
        // Check if the value of the connection is a jinja2 variable or the string 'finish'
        // It is used to determine if the connection should be added to the node
        return checkJinja2Syntaxis(value) || value === 'finish';
    };

    const updateOutputConnection = (): void => {
        const di = props.data.options!.di;
        const node = di.editor.getNode(props.data.options!.nodeId.reteNodeId);
        const socket = props.data.options!.socket;
        const outputIDs: string[] = [];
        const casesID: string[] = [];

        if (node.outputs) {
            for (const output in node.outputs) {
                outputIDs.push(output);
            }
        }

        gptAssistantData.cases.forEach((item) => {
            if (!checkAddConnection(item.o_connection)) {
                casesID.push(item.id);
            }
        });

        outputIDs.map((output) => {
            if (!casesID.includes(output)) {
                node.removeOutput(output);
            }
        });

        gptAssistantData.cases.forEach((item) => {
            if (!outputIDs.includes(item.id) && !checkAddConnection(item.o_connection)) {
                node.addOutput(item.id, new Classic.Output(socket, item.id, false));
            }
        });
        di.updateNode(props.data.options!.nodeId.reteNodeId);
    };

    const validateForm = (): string | undefined => {
        if (gptAssistantData.cases.length === 0) {
            return t('gpt_assistant.you_must_add_at_least_one_case');
        } else if (nodeNameAlreadyExists(gptAssistantData.name, props.data.options?.nodeId!, props.data.options?.di.editor!)) {
            return t('gpt_assistant.name_already_exists');
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const error = validateForm();
        if (error) {
            showErrorMessage(error);
            return;
        }

        props.data.options!.modalInfo = {
            name: gptAssistantData.name,
            assistant_id: gptAssistantData.assistant_id,
            api_key: gptAssistantData.api_key,
            variable: gptAssistantData.variable,
            validation: gptAssistantData.validation,
            instructions: gptAssistantData.instructions,
            initial_info: gptAssistantData.initial_info,
            validation_fail: gptAssistantData.validation_fail,
            cases: gptAssistantData.cases,
        };
        if (enableInactivityOptions) {
            const options = gptAssistantData.inactivity_options;
            if (!options?.chat_timeout || !options?.warning_message || !options?.time_between_attempts || !options?.attempts) {
                showErrorMessage(t('gpt_assistant.you_must_fill_all_inactivity_options_fields'));
                return;
            }
            props.data.options!.modalInfo.inactivity_options = gptAssistantData.inactivity_options;
        } else {
            props.data.options!.modalInfo.inactivity_options = undefined;
        }
        props.data.options!.di.updateControl(props.data.id);

        updateOutputConnection();
        updateNodeHeigth();
        onClose();
    };

    const addNewCase = (case_id: string = ''): void => {
        setGptAssistantData({
            ...gptAssistantData,
            cases: [{ id: case_id, o_connection: '', variables: [] }, ...gptAssistantData.cases],
        });
    };

    const removeCase = (index: number): void => {
        const newCases = [...gptAssistantData.cases];
        newCases.splice(index, 1);
        setGptAssistantData({ ...gptAssistantData, cases: newCases });
    };

    const saveCase = (index: number, key: 'id' | 'o_connection', value: string): void => {
        const newCases = [...gptAssistantData.cases];
        newCases[index][key] = value;
        setGptAssistantData({ ...gptAssistantData, cases: newCases });
    };

    const addVariable = (caseIndex: number): void => {
        const newCases = [...gptAssistantData.cases];
        if (newCases[caseIndex].variables) {
            newCases[caseIndex].variables?.push({ key: '', value: '' });
        }
        setGptAssistantData({ ...gptAssistantData, cases: newCases });
    };

    const saveVariable = (index: number, caseIndex: number, key: 'key' | 'value', value: string): void => {
        const newCases = [...gptAssistantData.cases];
        if (newCases[caseIndex].variables != undefined) {
            (newCases[caseIndex].variables ?? [])[index][key] = value;
        }
        setGptAssistantData({ ...gptAssistantData, cases: newCases });
    };

    const removeVariable = (caseIndex: number, index: number): void => {
        const newCases = [...gptAssistantData.cases];
        if (newCases[caseIndex].variables) {
            newCases[caseIndex].variables?.splice(index, 1);
        }
        setGptAssistantData({ ...gptAssistantData, cases: newCases });
    };

    const updateNodeHeigth = (): void => {
        const nodeHeigth = 180 + (gptAssistantData.cases.length * 30);
        const editor_node = props.data.options!.di.editor.getNode(props.data.options!.nodeId.reteNodeId);
        editor_node.height = nodeHeigth;
        props.data.options!.di.updateNode(props.data.options!.nodeId.reteNodeId);
    };

    const onDragEnd = (result: DropResult): void => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const cases:
            Case[] = reorder(
                gptAssistantData.cases,
                result.source.index,
                result.destination.index
            );

        setGptAssistantData({
            ...gptAssistantData,
            cases: [...cases],
        });
    };

    const handleExpandClick = () => {
        setExpanded((prev) => !prev);
    };

    return (
        <div>
            <IconButton
                aria-label="Node Options"
                size="small"
                className='open-button'
                onPointerDown={(e): void => e.stopPropagation()}
                onClick={onOpen()}
            >
                <Settings fontSize="medium" />
            </IconButton>

            <Dialog
                open={open}
                onClose={onClose}
                fullWidth={true}
                maxWidth={'md'}
                scroll={'paper'}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        handleSubmit(event);
                    },
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title"  textAlign={'center'} variant='h6' gutterBottom>
                    {t('gpt_assistant.assistant_node_options')}
                    <span className='close' onClick={onClose}>
                        &times;
                    </span>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Box
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Typography variant="caption" display="block" gutterBottom>
                            {t('gpt_assistant.required_fields')}
                        </Typography>
                        <TextField
                            label={t('gpt_assistant.name')}
                            id='node-name'
                            value={gptAssistantData.name}
                            required
                            onChange={(e): void => setGptAssistantData({ ...gptAssistantData, name: e.target.value })}
                            sx={{ m: 1, width: '90ch' }}
                            size="small"
                        />
                        <TextField
                            label={t('gpt_assistant.assistant_id')}
                            id='assistant-id'
                            value={gptAssistantData.assistant_id}
                            required
                            onChange={(e): void => setGptAssistantData({ ...gptAssistantData, assistant_id: e.target.value })}
                            sx={{ m: 1, width: '44ch' }}
                            size="small"
                        />
                        <FormControl sx={{ m: 1, width: '44ch' }} variant="outlined" size='small'>
                            <InputLabel htmlFor="api-key">{t('gpt_assistant.api_key')}</InputLabel>
                            <OutlinedInput
                                id="api-key"
                                type={showPassword ? 'text' : 'password'}
                                onChange={(e): void => setGptAssistantData({ ...gptAssistantData, api_key: e.target.value })}
                                value={gptAssistantData.api_key}
                                required
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={(): void => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label={t('gpt_assistant.api_key')}
                            />
                        </FormControl>
                        <TextField
                            label={t('gpt_assistant.variable_name')}
                            id='variable-name'
                            value={gptAssistantData.variable}
                            required
                            onChange={(e): void => setGptAssistantData({ ...gptAssistantData, variable: e.target.value })}
                            sx={{ m: 1, width: '44ch' }}
                            size="small"
                        />
                        <TextField
                            label={t('gpt_assistant.validation_attempts')}
                            id="validation-attempts"
                            type='number'
                            value={gptAssistantData.validation_fail?.attempts ?? ''}
                            onChange={(e): void => setGptAssistantData({
                                ...gptAssistantData, validation_fail: {
                                    ...gptAssistantData.validation_fail, attempts: e.target.value,
                                },
                            })}
                            sx={{ m: 1, width: '44ch' }}
                            size="small"
                        />
                        <TextField
                            label={t('gpt_assistant.instructions')}
                            id="instructions"
                            value={gptAssistantData.instructions}
                            onChange={(e): void => setGptAssistantData({ ...gptAssistantData, instructions: e.target.value })}
                            sx={{ m: 1, width: '90ch' }}
                            multiline
                            size="small"
                        />
                        <TextField
                            label={t('gpt_assistant.initial_info')}
                            id="initial-info"
                            value={gptAssistantData.initial_info}
                            onChange={(e): void => setGptAssistantData({ ...gptAssistantData, initial_info: e.target.value })}
                            sx={{ m: 1, width: '90ch' }}
                            multiline
                            size="small"
                        />
                        <Box sx={{ m: 0, width: '91ch' }}>
                            <JinjaCodeMirrorEditor
                                label={'gpt_assistant.validation'}
                                pr='1%'
                                jinjaExpression={gptAssistantData.validation}
                                onChangeFunction={(value: string): void => setGptAssistantData({ ...gptAssistantData, validation: value })}
                            />
                        </Box>
                        <Grid container spacing={2} className='mt-16 mb-16'>
                            <Grid item xs={11}>
                                <Typography textAlign='left' variant='subtitle1'>{t('gpt_assistant.inactivity_options')}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <div className='float-right'>
                                    <Switch
                                        size="small"
                                        checked={enableInactivityOptions}
                                        color='primary'
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                        onChange={(): void => setEnableInactivityOptions(!enableInactivityOptions)}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <TextField
                            label={t('gpt_assistant.chat_timeout')}
                            id="chat-timeout"
                            type='number'
                            value={gptAssistantData.inactivity_options?.chat_timeout}
                            onChange={(e): void => setGptAssistantData({ ...gptAssistantData, inactivity_options: { ...gptAssistantData.inactivity_options, chat_timeout: e.target.value } })}
                            sx={{ m: 1, width: '44ch' }}
                            disabled={!enableInactivityOptions}
                            size="small"
                        />
                        <TextField
                            label={t('gpt_assistant.warning_message')}
                            id="warning-message"
                            value={gptAssistantData.inactivity_options?.warning_message}
                            onChange={(e): void => setGptAssistantData({ ...gptAssistantData, inactivity_options: { ...gptAssistantData.inactivity_options, warning_message: e.target.value } })}
                            sx={{ m: 1, width: '44ch' }}
                            disabled={!enableInactivityOptions}
                            multiline
                            size="small"
                        />
                        <TextField
                            label={t('gpt_assistant.time_between_attempts')}
                            id="time-between-attempts"
                            type='number'
                            value={gptAssistantData.inactivity_options?.time_between_attempts}
                            onChange={(e): void => setGptAssistantData({
                                ...gptAssistantData,
                                inactivity_options: {
                                    ...gptAssistantData.inactivity_options, time_between_attempts: e.target.value,
                                },
                            })}
                            sx={{ m: 1, width: '44ch' }}
                            disabled={!enableInactivityOptions}
                            size="small"
                        />
                        <TextField
                            label={t('gpt_assistant.attempts')}
                            id="attempts"
                            type='number'
                            value={gptAssistantData.inactivity_options?.attempts}
                            onChange={(e): void => {
                                const status = e.target.value !== '' ? true : false;
                                setEnableValidationFail(status);
                                setGptAssistantData({
                                    ...gptAssistantData,
                                    inactivity_options: {
                                        ...gptAssistantData.inactivity_options, attempts: e.target.value,
                                    },
                                });
                            }}
                            sx={{ m: 1, width: '44ch' }}
                            disabled={!enableInactivityOptions}
                            size="small"
                        />
                        <Grid container spacing={0} className='cases-container' sx={{ pl: 3, pr: 3 }}>
                            <Grid container spacing={0} sx={{ mb: 1 }}>
                                <Grid item xs={10}>
                                    <Typography
                                        textAlign="left"
                                        variant='h6'
                                        sx={{ ml: 1, fontSize: '1.2rem' }}
                                    >
                                        {t('gpt_assistant.cases')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Box className='round-button' textAlign={'end'}>
                                        <Tooltip title={t('case_component.tooltip_add_case')}>
                                            <IconButton
                                                aria-label="round-button"
                                                onClick={() => addNewCase()}
                                                sx={{ ml: 5 }}
                                            >
                                                <Add sx={{ fontSize: '1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                <Grid item xs={1} justifySelf={'start'}>
                                    <Box className='expand-case-option' textAlign={'start'}>
                                        <IconButton
                                            aria-label='expand-case-item'
                                            onClick={() => { handleExpandClick(); }}
                                            sx={{ ml: 1 }}
                                        >
                                            {!expanded && <ExpandMore sx={{ fontSize: '1rem' }} />}
                                            {expanded && <ExpandLess sx={{ fontSize: '1rem' }} />}
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppableGptCases">
                                    {(provided: DroppableProvided) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            {gptAssistantData.cases.map((item, index) => (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{ ml: 1, pr: 4 }}
                                                    key={`components-gpt-index-cases-${index}`}
                                                >
                                                    <CaseComponent
                                                        key={index}
                                                        data={item}
                                                        index={index}
                                                        removeCase={removeCase}
                                                        saveCase={saveCase}
                                                        addVariable={addVariable}
                                                        removeVariable={removeVariable}
                                                        saveVariable={saveVariable}
                                                        expanded={expanded}
                                                        textFileWidth={'47%'}
                                                    />
                                                </Grid>
                                            ))}
                                            {provided.placeholder}
                                        </Grid>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Grid>

                        <Box className={'mt-16'} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            {formErrorVisible &&
                                <Alert sx={{ m: 1, width: '100%' }} severity="error" icon={<WarningAmber />}>
                                    {formErrorMessage}
                                </Alert>
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" startIcon={<Cancel />} onClick={onClose}>{t('gpt_assistant.cancel')}</Button>
                    <Button type="submit" variant="contained" startIcon={<CheckCircle />}>{t('accept')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};